import { gql } from '@apollo/client'

// Need to split fragments because while graphql is incredibly verbose
// you cant even just include all...
// include the ones you need at the start of your queries with ${<fragment>}

// schema.graphql
export const fragmentUser = gql`
    fragment fragmentUser on User {
        id
        name
    }
`
export const fragmentDataTypeChange = gql`
    ${fragmentUser}
    fragment fragmentDataTypeChange on DataTypeChange {
        user {
            ...fragmentUser
        }
        timestamp
        changeType
    }
`

// dataTypeQuery.graphql
export const fragmentAttributeCommon = gql`
    fragment fragmentAttributeCommon on AttributeCommon {
        identifier
        name
        description
        visible
        _next
    }
`

export const fragmentsAttributes = gql`
    ${fragmentAttributeCommon}
    fragment fragmentAttributeText on AttributeText {
        id
        common {
            ...fragmentAttributeCommon
        }
        multiLine
        defaultValue
        required
    }
    fragment fragmentAttributeNumber on AttributeNumber {
        id
        common {
            ...fragmentAttributeCommon
        }
        integer
        min
        max
        defaultValue
    }
    fragment fragmentAttributeBoolean on AttributeBoolean {
        id
        common {
            ...fragmentAttributeCommon
        }
        defaultValue
    }
    fragment fragmentAttributeAsset on AttributeAsset {
        id
        common {
            ...fragmentAttributeCommon
        }
        required
    }
    fragment fragmentAttributeLink on AttributeLink {
        id
        common {
            ...fragmentAttributeCommon
        }
        allowed
        required
    }
    fragment fragmentAttributeDate on AttributeDate {
        id
        common {
            ...fragmentAttributeCommon
        }
    }
    fragment fragmentAttributeSelection on AttributeSelection {
        id
        common {
            ...fragmentAttributeCommon
        }
        options {
            id
            name
            description
        }
        defaultValue
        required
    }
    fragment fragmentAttributeCollection on AttributeCollection {
        id
        common {
            ...fragmentAttributeCommon
        }
        dataType
    }
`

export const fragmentDataTypeAttribtues = gql`
    ${fragmentsAttributes}
    fragment fragmentDataTypeAttribtues on DataTypeAttributes {
        _head
        attributeTextList {
            ...fragmentAttributeText
        }
        attributeNumberList {
            ...fragmentAttributeNumber
        }
        attributeBooleanList {
            ...fragmentAttributeBoolean
        }
        attributeAssetList {
            ...fragmentAttributeAsset
        }
        attributeLinkList {
            ...fragmentAttributeLink
        }
        attributeDateList {
            ...fragmentAttributeDate
        }
        attributeSelectionList {
            ...fragmentAttributeSelection
        }
        attributeCollectionList {
            ...fragmentAttributeCollection
        }
    }
`
export const fragmentDataType = gql`
    ${fragmentDataTypeChange}
    ${fragmentDataTypeAttribtues}
    fragment fragmentDataType on DataType {
        id
        category
        identifier
        name
        description
        created {
            ...fragmentDataTypeChange
        }
        updated {
            ...fragmentDataTypeChange
        }
        attributes {
            ...fragmentDataTypeAttribtues
        }
    }
`
export const fragmentDataItemChange = gql`
    ${fragmentUser}
    fragment fragmentDataItemChange on DataItemChange {
        user {
            ...fragmentUser
        }
        timestamp
        changeType
    }
`

export const fragmentDataItemValues = gql`
    fragment fragmentDataItemValues on DataItemValues {
        attributeTextValues {
            attributeID
            value
        }
        attributeNumberValues {
            attributeID
            value
        }
        attributeBooleanValues {
            attributeID
            value
        }
        attributeAssetValues {
            attributeID
            value
            alt
        }
        attributeLinkValues {
            attributeID
            value
            newWindow
        }
        attributeDateValues {
            attributeID
            value
        }
        attributeSelectionValues {
            attributeID
            value
        }
        attributeCollectionValues {
            attributeID
            value {
                id
                attributeTextValues {
                    attributeID
                    value
                }
                attributeNumberValues {
                    attributeID
                    value
                }
                attributeBooleanValues {
                    attributeID
                    value
                }
                attributeAssetValues {
                    attributeID
                    value
                    alt
                }
                attributeLinkValues {
                    attributeID
                    value
                    newWindow
                }
                attributeSelectionValues {
                    attributeID
                    value
                }
                attributeDateValues {
                    attributeID
                    value
                }
            }
        }
    }
`

export const fragmentDataItem = gql`
    ${fragmentDataItemChange}
    ${fragmentDataItemValues}
    fragment fragmentDataItem on DataItem {
        id
        enabled
        published
        created {
            ...fragmentDataItemChange
        }
        updated {
            ...fragmentDataItemChange
        }
        values {
            ...fragmentDataItemValues
        }
    }
`
export const fragmentDataItemVersion = gql`
    ${fragmentDataItemValues}
    ${fragmentDataTypeAttribtues}
    ${fragmentDataItemChange}
    fragment fragmentDataItemVersion on DataItemVersion {
        id
        published
        updated {
            ...fragmentDataItemChange
        }
        snapshotValues {
            ...fragmentDataItemValues
        }
        snapshotAttributes {
            ...fragmentDataTypeAttribtues
        }
        snapshotAttributesCollection {
            dataType
            attributes {
                ...fragmentDataTypeAttribtues
            }
        }
    }
`
