import { Delete, Edit, FileCopy, History, Settings, Visibility, VisibilityOff } from '@mui/icons-material'
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import VersionHistory, { HistoryVersion } from 'components/VersionHistory'
import { format } from 'date-fns'
import { Page, PageVersion, State } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface PageDetailsPanelProps {
    onEditPage: (page: Page) => void
    onDeactivate: (pageID: string) => void
    onEditPageSettings: (pageID: string) => void
    onDuplicate: (pageID: string) => void
    onDelete: (pageID: string) => void
    showPageVersion: (version: PageVersion) => void
    reactivatePageVersion: (version: PageVersion) => void
    loadHistory: (id: string) => void
    openExportDialiog: () => void
    page: Page
    historyMap: Record<string, PageVersion[]>
    selectedVersion: PageVersion | undefined
}

const timeFormat = 'dd.MM.yyyy HH:mm'

const getLastPublished = (page: Page): string => {
    return page.lastPublished ? format(new Date(page.lastPublished), timeFormat) : '-'
}

const PageDetailsPanel = (props: PageDetailsPanelProps): JSX.Element => {
    const {
        onEditPage,
        onDeactivate,
        onEditPageSettings,
        onDuplicate,
        onDelete,
        openExportDialiog,
        reactivatePageVersion,
        showPageVersion,
        loadHistory,
        page,
        historyMap,
        selectedVersion,
    } = props
    const { t } = useTranslation()

    const lastPublished = getLastPublished(page)

    const [displayPageVersion, setDisplayPageVersion] = useState<boolean>(false)

    useEffect(() => {
        setDisplayPageVersion(false)
    }, [page.id])

    const showHistory = () => {
        setDisplayPageVersion(!displayPageVersion)

        // map.has but with record, since maps are not serializeable and thus should not be used in redux
        if (!Object.keys(historyMap).some((key) => key === page.id)) loadHistory(page.id)
    }

    const versions: HistoryVersion<PageVersion>[] =
        historyMap[page.id]?.map((version) => ({
            id: version.id,
            published: version.state === State.PUBLISHED,
            timestamp: version.updatedAt,
            user: version.updatedBy.name,
            item: version,
        })) ?? []
    return (
        <Grid container direction="column" p={2} gap={1}>
            <Grid container item direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                    <Typography variant="h6">{page.currentVersion.pageSettings.htmlTitle}</Typography>
                </Grid>
                <Grid item xs={2} justifyItems={'flex-end'}>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.edit')} placement={'right'}>
                        <IconButton
                            onClick={() => {
                                onEditPage(page)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">ID</TableCell>
                                <TableCell align="right">{page.id.replace('Page/', '')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.lastChange')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.lastChange')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    {format(new Date(page.currentVersion.updatedAt), timeFormat)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.changedBy')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.changedBy')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={page.currentVersion.updatedBy.name} placement="left">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '130px',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            {page.currentVersion.updatedBy.name}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.lastPublish')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.pageDetailsPanel.lastPublish')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{lastPublished}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.pageDetailsPanel.status')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            St<a onClick={openExportDialiog}>a</a>tus
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{t(`common.${page.currentVersion.state}`)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={2}>
                    <Tooltip
                        title={t(`drawerPanels.pageDetailsPanel.${!page.deactivated ? 'deactivate' : 'activate'}`)}
                    >
                        <IconButton onClick={() => onDeactivate(page.id)}>
                            {!page.deactivated && <Visibility />}
                            {page.deactivated && <VisibilityOff />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={10} display="flex" alignContent="end">
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.settings')}>
                        <IconButton onClick={() => onEditPageSettings(page.id)}>
                            <Settings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.duplicate')}>
                        <IconButton onClick={() => onDuplicate(page.id)}>
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.delete')}>
                        <IconButton onClick={() => onDelete(page.id)}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.pageDetailsPanel.versionHistory')}>
                        <IconButton onClick={showHistory}>
                            <History color={displayPageVersion ? 'primary' : 'action'} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {displayPageVersion && (
                <VersionHistory<PageVersion>
                    versions={versions}
                    currentlyShowingVersion={selectedVersion?.id ?? ''}
                    reactivate={(version) => reactivatePageVersion(version.item)}
                    show={(version) => showPageVersion(version.item)}
                />
            )}
        </Grid>
    )
}

export default PageDetailsPanel
