import {
    Asset,
    AttributeAsset,
    AttributeCollectionValueItem,
    AttributeLink,
    AttributeNumber,
    AttributeSelection,
    AttributeText,
    DataType,
} from 'graphql/types'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AttributeUnion, DataItemUnion } from 'utils/dataType/types'
import { FormError } from 'utils/types'

export interface UseDataErrorsReturn {
    errors: FormError[]
    setErrors: (errors: FormError[]) => void
}

export const useDataErrors = (
    assets: Asset[],
    selectedItem: DataItemUnion | undefined,
    attributes: AttributeUnion[],
    subTypes: DataType[],
): UseDataErrorsReturn => {
    const [errors, setErrors] = useState<FormError[]>([])

    const { t } = useTranslation()

    const values = JSON.stringify(selectedItem?.values ?? {})

    useLayoutEffect(() => {
        const newErrors: FormError[] = []
        if (!selectedItem) return
        if (selectedItem.values.length === 0) return

        // Inner Error handling
        selectedItem.values.forEach((value) => {
            // ATTRIBUTE ASSET
            if (value.__typename === 'AttributeAssetValue') {
                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                    | AttributeAsset
                    | undefined
                // empty value
                if (assets.find((item) => item.id === value.value) === undefined && attribute?.required) {
                    newErrors.push({
                        type: `innerAssetEmpty${value.attributeID}`,
                        message: t('common.errors.required'),
                    })
                }
            }
            // ATTRIBUTE BOOLEAN does not have properties to check
            // ATTRIBUTE COLLECTION
            if (value.__typename === 'AttributeCollectionValue') {
                // empty value
                // if (value.value.length === 0) {
                //         newErrors.push({
                //             type: `innerCollectionEmpty${value.attributeID}`,
                //             message: t("common.errors.required"),
                //         })
                // }
                if (value.value.length !== 0) {
                    const notEmptyAttributes: (keyof AttributeCollectionValueItem)[] = []
                    if (value.value[0].attributeAssetValues.length > 0) {
                        notEmptyAttributes.push('attributeAssetValues')
                    }
                    if (value.value[0].attributeBooleanValues.length > 0) {
                        notEmptyAttributes.push('attributeBooleanValues')
                    }
                    if (value.value[0].attributeLinkValues.length > 0) {
                        notEmptyAttributes.push('attributeLinkValues')
                    }
                    if (value.value[0].attributeNumberValues.length > 0) {
                        notEmptyAttributes.push('attributeNumberValues')
                    }
                    if (value.value[0].attributeSelectionValues.length > 0) {
                        notEmptyAttributes.push('attributeSelectionValues')
                    }
                    if (value.value[0].attributeTextValues.length > 0) {
                        notEmptyAttributes.push('attributeTextValues')
                    }
                    if (value.value[0].attributeDateValues.length > 0) {
                        notEmptyAttributes.push('attributeDateValues')
                    }
                    notEmptyAttributes.forEach((usedAttribute) => {
                        value.value.forEach((item, index) => {
                            // ATTRIBUTE ASSET
                            if (usedAttribute === 'attributeAssetValues') {
                                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                                    | AttributeText
                                    | undefined
                                item.attributeAssetValues.forEach((entry) => {
                                    // empty value
                                    if (
                                        assets.find((item) => item.id === entry.value) === undefined &&
                                        attribute?.required
                                    ) {
                                        newErrors.push({
                                            type: `innerAssetEmpty${entry.attributeID}-${index}`,
                                            message: t('common.errors.required'),
                                        })
                                    }
                                })
                            }
                            // ATTRIBUTE BOOLEAN does not have properties to check
                            // ATTRIBUTE LINK
                            if (usedAttribute === 'attributeLinkValues') {
                                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                                    | AttributeText
                                    | undefined
                                item.attributeLinkValues.forEach((entry) => {
                                    // empty value
                                    if (entry.value === '' && attribute?.required) {
                                        newErrors.push({
                                            type: `innerLinkEmpty${entry.attributeID}-${index}`,
                                            message: t('common.errors.required'),
                                        })
                                    }
                                })
                            }
                            // ATTRIBUTE DATE
                            if (usedAttribute === 'attributeDateValues') {
                                item.attributeDateValues.forEach((entry) => {
                                    // empty value
                                    if (Number.isNaN(entry.value)) {
                                        newErrors.push({
                                            type: `innerDateEmpty${entry.attributeID}-${index}`,
                                            message: t('common.errors.required'),
                                        })
                                    }
                                })
                            }
                            // ATTRIBUTE NUMBER
                            if (usedAttribute === 'attributeNumberValues') {
                                item.attributeNumberValues.forEach((entry) => {
                                    // empty value
                                    if (Number.isNaN(entry.value)) {
                                        newErrors.push({
                                            type: `innerNumberEmpty${entry.attributeID}-${index}`,
                                            message: t('common.errors.required'),
                                        })
                                    }
                                    const attribute = attributes.find(
                                        (attribute) => attribute.id === value.attributeID,
                                    ) as any
                                    const usableSubType = subTypes.find((type) => type.id === attribute.dataType)
                                        ?.attributes.attributeNumberList
                                    const subType = usableSubType?.find((subType) => subType.id === entry.attributeID)
                                    // less than min
                                    if (
                                        subType &&
                                        subType.min !== undefined &&
                                        subType.min !== null &&
                                        entry.value < subType.min
                                    ) {
                                        newErrors.push({
                                            type: `innerNumberMin${entry.attributeID}-${index}`,
                                            message: `${t('common.errors.equalOrGreater')} ${subType.min}`,
                                        })
                                    }
                                    // greater than max
                                    if (
                                        subType &&
                                        subType.max !== undefined &&
                                        subType.max !== null &&
                                        entry.value > subType.max
                                    ) {
                                        newErrors.push({
                                            type: `innerNumberMax${entry.attributeID}-${index}`,
                                            message: `${t('common.errors.equalOrLess')}${subType.max}`,
                                        })
                                    }
                                    // integer
                                    if (subType && subType.integer && !Number.isInteger(entry.value)) {
                                        newErrors.push({
                                            type: `innerNumberInteger${entry.attributeID}-${index}`,
                                            message: t('common.errors.integer'),
                                        })
                                    }
                                })
                            }
                            // ATTRIBUTE SELECTION
                            if (usedAttribute === 'attributeSelectionValues') {
                                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                                    | AttributeText
                                    | undefined
                                item.attributeSelectionValues.forEach((entry) => {
                                    if (entry.value === '' && attribute?.required) {
                                        newErrors.push({
                                            type: `innerSelectionEmpty${entry.attributeID}-${index}`,
                                            message: t('common.errors.required'),
                                        })
                                    }
                                })
                            }
                            // ATTRIBUTE TEXT
                            if (usedAttribute === 'attributeTextValues') {
                                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                                    | AttributeText
                                    | undefined
                                item.attributeTextValues.forEach((entry) => {
                                    if (entry.value === '' && attribute?.required) {
                                        newErrors.push({
                                            type: `innerTextEmpty${entry.attributeID}-${index}`,
                                            message: t('common.errors.required'),
                                        })
                                    }
                                })
                            }
                        })
                    })
                }
            }
            // ATTRIBUTE LINK
            if (value.__typename === 'AttributeLinkValue') {
                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                    | AttributeLink
                    | undefined
                // empty value
                if (value.value === '' && attribute?.required) {
                    newErrors.push({
                        type: `innerLinkEmpty${value.attributeID}`,
                        message: t('common.errors.required'),
                    })
                }
            }
            // ATTRIBUTE DATE
            if (value.__typename === 'AttributeDateValue') {
                // empty value
                if (Number.isNaN(value.value)) {
                    newErrors.push({
                        type: `innerDateEmpty${value.attributeID}`,
                        message: t('common.errors.required'),
                    })
                }
            }
            // ATTRIBUTE NUMBER
            if (value.__typename === 'AttributeNumberValue') {
                // empty value
                if (Number.isNaN(value.value)) {
                    newErrors.push({
                        type: `innerNumberEmpty${value.attributeID}`,
                        message: t('common.errors.required'),
                    })
                }
                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                    | AttributeNumber
                    | undefined
                // less than min
                if (attribute && attribute.min && value.value < attribute.min) {
                    newErrors.push({
                        type: `innerNumberMin${value.attributeID}`,
                        message: `${t('common.errors.equalOrGreater')} ${attribute.min}`,
                    })
                }
                // greater than max
                if (attribute && attribute.max && value.value > attribute.max) {
                    newErrors.push({
                        type: `innerNumberMax${value.attributeID}`,
                        message: `${t('common.errors.equalOrLess')}${attribute.max}`,
                    })
                }
                // integer
                if (attribute && attribute.integer && !Number.isInteger(value.value)) {
                    newErrors.push({
                        type: `innerNumberInteger${value.attributeID}`,
                        message: t('common.errors.integer'),
                    })
                }
            }
            // ATTRIBUTE SELECTION
            if (value.__typename === 'AttributeSelectionValue') {
                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                    | AttributeSelection
                    | undefined
                if (value.value === '' && attribute?.required) {
                    newErrors.push({
                        type: `innerSelectionEmpty${value.attributeID}`,
                        message: t('common.errors.required'),
                    })
                }
            }
            // ATTRIBUTE TEXT
            if (value.__typename === 'AttributeTextValue') {
                const attribute = attributes.find((attribute) => attribute.id === value.attributeID) as
                    | AttributeText
                    | undefined
                // empty value
                if (value.value === '' && attribute?.required) {
                    newErrors.push({
                        type: `innerTextEmpty${value.attributeID}`,
                        message: t('common.errors.required'),
                    })
                }
            }
        })
        setErrors(newErrors)
    }, [t, subTypes, selectedItem, attributes, assets, values])

    return { errors, setErrors }
}
