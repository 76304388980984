import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Button, Grid, Link, MenuItem, Select, styled, Typography } from '@mui/material'
import { Buffer } from 'buffer'
import { MultiEditAllowedTypes } from 'graphql/types'
import { ChangeEvent, useEffect } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

export interface MultiEditPageSelectionProps {
    openPageLinkSelector: () => void
}

function base64ToBlob(base64: string): Blob {
    // Split the base64 string into data and contentType
    const byteArray = Buffer.from(base64, 'base64')

    // Create a Blob from the byteArray
    return new Blob([byteArray], { type: 'application/vnd.ms-excel' })
}

const MultiEditPageSelection = (props: MultiEditPageSelectionProps): JSX.Element => {
    const { openPageLinkSelector } = props

    const excelDownload = useStoreState((state) => state.multiPageEditModel.excelDownload)
    const allowedTypes = useStoreState((state) => state.multiPageEditModel.allowedTypes)
    const selectedFile = useStoreState((state) => state.multiPageEditModel.selectedFile)
    const selectedPages = useStoreState((state) => state.multiPageEditModel.selectedPages)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)

    const setSelectedFile = useStoreActions((actions) => actions.multiPageEditModel.setSelectedFile)
    const downLoadMultiEditExcel = useStoreActions((actions) => actions.multiPageEditModel.downLoadMultiEditExcel)
    const setExcelDownload = useStoreActions((actions) => actions.multiPageEditModel.setExcelDownload)
    const setAllowedTypes = useStoreActions((actions) => actions.multiPageEditModel.setAllowedTypes)

    const handleDownloadExcel = () => {
        downLoadMultiEditExcel({ websiteID: selectedWebsite?.id ?? '', pages: selectedPages, allowedTypes })
    }

    useEffect(() => {
        if (excelDownload !== '') {
            // Create a blob from the base64 string
            const url = window.URL.createObjectURL(base64ToBlob(excelDownload))
            // Create a link element and click it
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'page_content_for_editing.xlsx')
            document.body.appendChild(link)
            link.click()

            // Clean up
            setExcelDownload('')
        }
    }, [excelDownload, setExcelDownload])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">Instructions</Typography>
                <Typography variant="body1">
                    To bulk edit a number of pages first select the pagtes you want to edit. If you have done that then
                    you can download an excel with all the current content of the selected pages. Then edit the excel
                    sheet and upload the file here. The file will be checked for errors. If there are any, you will see
                    info what to fix. If everything is fine you just need to accept the changes to edit the pages.
                </Typography>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Button
                    fullWidth
                    variant="contained"
                    color={'secondary'}
                    style={{ marginTop: 16 }}
                    onClick={openPageLinkSelector}
                >
                    Select pages
                    {selectedPages.length > 0 && ` (${selectedPages.length})`}
                </Button>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Typography>Excel contains only:</Typography>
                <Select
                    id="allowedTypes"
                    value={allowedTypes}
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                        setAllowedTypes(e.target.value as MultiEditAllowedTypes)
                    }}
                >
                    <MenuItem value={MultiEditAllowedTypes.TEXT}>Text</MenuItem>
                    <MenuItem value={MultiEditAllowedTypes.MEDIA_LINK}>Media & Link</MenuItem>
                    <MenuItem value={MultiEditAllowedTypes.ALL}>All</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Link
                    disabled={selectedPages.length == 0}
                    component="button"
                    onClick={handleDownloadExcel}
                    variant="body2"
                >
                    Download Excel
                </Link>
            </Grid>
            <Grid item xs={12} mt={10}>
                {selectedFile && <p>{selectedFile.name}</p>}
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    choose Excel file
                    <VisuallyHiddenInput
                        accept=".xls,.xlsx"
                        type="file"
                        multiple={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.files) {
                                setSelectedFile(e.target.files[0])
                            } else {
                                setSelectedFile(undefined)
                            }
                        }}
                    />
                </Button>
            </Grid>
        </Grid>
    )
}

export default MultiEditPageSelection
