import DrawerGrid from 'components/DrawerGrid'
import { DrawerGridProps, DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import EditorHeader from 'components/EditorHeader'
import PagePreview from 'components/PagePreview'
import AddElementPanel from 'drawerPanels/AddElementPanel'

import EditElementPanel from 'drawerPanels/EditElementPanel/EditElementPanel'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { useVersionDaemon } from 'utils/checkVersionDaemon'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { Breakpoint, EditingDataType, PagePreviewEvent, PagePreviewEventType } from 'utils/types'

const PageEditPage = (): JSX.Element => {
    const drawerGridProps: DrawerGridProps = {}

    const { t } = useTranslation()
    const navigate = useNavigate()

    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    const currentlyEditingData = useStoreState((state) => state.model.currentlyEditingData)
    const editedPageContents = useStoreState((state) => state.model.editedPageContents)
    const addElementPosition = useStoreState((state) => state.model.addElementPosition)
    const blockData = useStoreState((state) => state.model.blockData)
    const currentlyEditingElement = useStoreState((state) => state.model.currentlyEditingElement)
    const breakpoints = useStoreState((state) => state.model.breakpoints)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)

    const editElement = useStoreActions((actions) => actions.model.editElement)
    const cancelEditElement = useStoreActions((actions) => actions.model.cancelEditElement)
    const copyToClipboard = useStoreActions((actions) => actions.model.copyToClipboard)
    const cutToClipboard = useStoreActions((actions) => actions.model.cutToClipboard)
    const deleteElement = useStoreActions((actions) => actions.model.deleteElement)
    const duplicateElement = useStoreActions((actions) => actions.model.duplicateElement)
    const toggleElementDeactivated = useStoreActions((actions) => actions.model.toggleElementDeactivated)
    const storeDataAndOpenElementAddDrawer = useStoreActions(
        (actions) => actions.model.storeDataAndOpenAddElementDrawer,
    )
    const cancelAddElement = useStoreActions((actions) => actions.model.cancelAddElement)
    const savePage = useStoreActions((actions) => actions.model.savePage)
    const saveBlock = useStoreActions((actions) => actions.model.saveBlock)
    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)

    const [contentHasNotChanged, setContentHasNotChanged] = useState<boolean>(false)
    const [showEditorPreview, setShowEditorPreview] = useState<boolean>(false)
    const [currentBP, setCurrentBP] = useState<Breakpoint>({
        identifier: 'xl',
        name: 'xl',
        editorCssWidth: '100%',
        fromWidthPixels: 1536,
    }) // TODO: pick this from available breakpoints

    let pagePreviewDisabled = false

    let editedItemTitle = ''
    if (currentlyEditingData) {
        editedItemTitle =
            (currentlyEditingData.type === EditingDataType.PAGE
                ? t('pages.pageEditPage.page')
                : t('pages.pageEditPage.block')) + currentlyEditingData.label
    }

    //initalise deamon to check version
    useVersionDaemon()

    // FIXME: Smoother editing with better close button placements and seamless switching between items

    const savePageContent = () => {
        if (currentlyEditingData) {
            if (currentlyEditingData.type === EditingDataType.PAGE) {
                savePage({
                    pageID: currentlyEditingData.id,
                    content: JSON.stringify(editedPageContents),
                }).then(() => {
                    startEditingPage({ currentlyEditingData: undefined, pageElementContents: [] })
                    cancelEditElement()
                    navigate(NavigationRoutes.PAGE_TREE)
                })
            } else {
                saveBlock({
                    blockID: currentlyEditingData.id,
                    content: JSON.stringify(editedPageContents),
                    name: currentlyEditingData.label,
                    identifier: currentlyEditingData.identifier,
                }).then(() => {
                    startEditingPage({ currentlyEditingData: undefined, pageElementContents: [] })
                    cancelEditElement()
                    navigate(NavigationRoutes.BLOCK_LIST)
                })
            }
        }
    }

    const cancelEdit = () => {
        const targetPage =
            currentlyEditingData && currentlyEditingData.type === EditingDataType.BLOCK
                ? NavigationRoutes.BLOCK_LIST
                : NavigationRoutes.PAGE_TREE

        if (contentHasNotChanged) {
            startEditingPage({ currentlyEditingData: undefined, pageElementContents: [] })
            cancelEditElement()
            navigate(targetPage)
        } else {
            if (confirm(t('pages.pageEditPage.confirmDiscard')) == true) {
                navigate(targetPage)
            }
        }
    }

    const content = JSON.stringify({ editedPageContents, currentlyEditingData })
    useEffect(() => {
        let contentHasChanged = false
        if (currentlyEditingData) {
            contentHasChanged = JSON.stringify(editedPageContents) === currentlyEditingData.contents
        }

        setContentHasNotChanged(contentHasChanged)
        // eslint-disable-next-line react-hooks/exhaustive-deps -- as included in json stringify
    }, [content])

    // add element panel
    if (addElementPosition) {
        drawerGridProps.primary = (
            <AddElementPanel
                displayBlockOptions={
                    currentlyEditingData !== undefined && currentlyEditingData.type === EditingDataType.PAGE
                }
                closeAddDrawer={() => cancelAddElement()}
            />
        )
        drawerGridProps.primaryWidth = DrawerWidth.MEDIUM
        // elements.collapsible = true
        pagePreviewDisabled = true
    }
    // edit element panel
    else if (currentlyEditingElement) {
        drawerGridProps.primary = <EditElementPanel />
        drawerGridProps.primaryWidth = DrawerWidth.LARGE
        drawerGridProps.collapsible = false

        pagePreviewDisabled = true
    }

    // edit view
    // else if (viewMode === CMSViewMode.EDIT) {
    //     elements.primary = (
    //         <PageTreePanel
    //             onSelectPage={(id) => {
    //                 if (id === selectedPage) setSelectedPage('')
    //                 else setSelectedPage(id)
    //             }}
    //             switchToSortMode={() => setViewMode(CMSViewMode.SORT)}
    //         />
    //     )

    //     if (selectedPage) {
    //         elements.secondary = <PageSettingsEditPanel cancelEdit={() => setViewMode(CMSViewMode.PAGETREE)} />
    //     }
    //     elements.secondaryWidth = DrawerWidth.MEDIUM
    //     elements.collapsible = false
    //     elements.primaryDisabled = true
    // }

    const previewPageEventHandler = (pe: PagePreviewEvent) => {
        if (pe.type === PagePreviewEventType.DELETE_ELEMENT) {
            deleteElement(pe.payload)
        } else if (pe.type === PagePreviewEventType.DUPLICATE_ELEMENT) {
            duplicateElement(pe.payload)
        } else if (pe.type === PagePreviewEventType.ADD_ELEMENT_BEFORE) {
            storeDataAndOpenElementAddDrawer({ elementID: pe.payload, before: true })
        } else if (pe.type === PagePreviewEventType.ADD_ELEMENT_AFTER) {
            storeDataAndOpenElementAddDrawer({ elementID: pe.payload, before: false })
        } else if (pe.type === PagePreviewEventType.COPY_ELEMENT) {
            copyToClipboard(pe.payload)
        } else if (pe.type === PagePreviewEventType.CUT_ELEMENT) {
            cutToClipboard(pe.payload)
        } else if (pe.type === PagePreviewEventType.EDIT_ELEMENT) {
            editElement(pe.payload)
        } else if (pe.type === PagePreviewEventType.TOGGLE_ELEMENT_DEACTIVATED) {
            toggleElementDeactivated(pe.payload)
        }
    }

    return (
        <>
            <EditorHeader
                editedItemTitle={editedItemTitle}
                disableSaveButton={contentHasNotChanged || savingCurrentVersionDisabled}
                breakpoints={breakpoints}
                selectedBreakpointId={currentBP.identifier}
                onCancelClick={cancelEdit}
                onSaveClick={savePageContent}
                onBreakpointSelected={(newBp) => {
                    setCurrentBP(newBp)
                }}
                onChangeEditorPreviewClick={() => {
                    setShowEditorPreview(!showEditorPreview)
                }}
                showEditorPreview={showEditorPreview}
            />
            <DrawerGrid
                {...drawerGridProps}
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        activeBreakpoint={currentBP}
                        pageElements={editedPageContents}
                        onPreviewPageEvent={previewPageEventHandler}
                        blocks={blockData}
                        disabled={pagePreviewDisabled}
                        editHelpersVisible={!showEditorPreview}
                    />
                }
                contentCentered={true}
            />
        </>
    )
}

export default PageEditPage
