import { Box } from '@mui/material'
import { default as CMSHeader } from 'components/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import ExportImportDialog from 'components/ExportImportDialog/ExportImportDialog'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import PagePreview from 'components/PagePreview'
import MultiEditPagePanel from 'drawerPanels/MultiEditPagePanel/MultiEditPagePanel'
import MultiPageCreatePanel from 'drawerPanels/MultiPageCreatePanel/MultiPageCreatePanel'
import MultiSelectPageTreePanel from 'drawerPanels/MultiSelectPageTreePanel/MultiSelectPageTreePanel'
import PageCreatePanel from 'drawerPanels/PageCreatePanel'
import PageDetailsPanel from 'drawerPanels/PageDetailsPanel/PageDetailsPanel'
import PageSettingsEditPanel from 'drawerPanels/PageSettingsEditPanel'
import PageSortPanel from 'drawerPanels/PageSortPanel'
import PageTreePanel from 'drawerPanels/PageTreePanel'
import { CreatePageInput, ImportType, OrderItemInput, Page, PageSettingInput, PageVersion } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { resolvePageURL } from 'utils/linkResolver'
import { getParsedPageElements } from 'utils/parseElementContent'
import { EditingDataType, PageElement } from 'utils/types'

export enum PageTreeViewMode {
    PAGETREE = 'PAGETREE',
    SORT = 'SORT',
    ADD = 'ADD',
    MULTI_ADD = 'MULTI_ADD',
    MULTI_EDIT = 'MULTI_EDIT',
    EDIT = 'EDIT',
    PAGE_LINK_SELECTOR = 'PAGE_LINK_SELECTOR',
    MULTI_PAGE_LINK_SELECTOR = 'MULTI_PAGE_LINK_SELECTOR',
    MULTI_EDIT_PAGE_LINK_SELECTOR = 'MULTI_EDIT_PAGE_LINK_SELECTOR',
}

const PageTreePage = (): JSX.Element => {
    //data
    const elements: any = {}

    //hooks and data from store
    const navigate = useNavigate()
    const { t } = useTranslation()

    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    // TODO: think how we can use this computed value without initialising it here
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const currentlySelectedPage = useStoreState((state) => state.model.currentlySelectedPage)
    const pageList = useStoreState((state) => state.model.pageList)
    const expanded = useStoreState((state) => state.model.expanded)
    const blockData = useStoreState((state) => state.model.blockData)
    const isMultiPageCreateLoading = useStoreState((state) => state.multiPageCreateModel.isLoading)
    const isMultiPageEditLoading = useStoreState((state) => state.multiPageEditModel.isLoading)
    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    //TODO: move the whole state from the page edit settings into the model
    const currentLanguageVersion = useStoreState((state) => state.model.currentLanguageVersion)
    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)
    const selectedPagesMultiEdit = useStoreState((state) => state.multiPageEditModel.selectedPages)
    const websiteList = useStoreState((state) => state.model.websiteList)
    const historyMap = useStoreState((state) => state.model.pageHistoryMap)
    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)
    const loadPage = useStoreActions((actions) => actions.model.loadPage)
    const deactivatePage = useStoreActions((actions) => actions.model.deactivatePage)
    const deletePage = useStoreActions((actions) => actions.model.deletePage)
    const duplicatePage = useStoreActions((actions) => actions.model.duplicatePage)
    const createPage = useStoreActions((actions) => actions.model.createPage)
    const savePage = useStoreActions((actions) => actions.model.savePage)
    const savePageOrder = useStoreActions((actions) => actions.model.savePageOrder)
    const setExpanded = useStoreActions((actions) => actions.model.setExpanded)
    const updateCurrentLanguageVersion = useStoreActions((actions) => actions.model.updateCurrentLanguageVersion)
    const updateMultiPageURLLink = useStoreActions((actions) => actions.multiPageCreateModel.updateMultiPageURLLink)
    const removeCurrentLanguageVersion = useStoreActions((actions) => actions.model.removeCurrentLanguageVersion)
    const clearCurrentLanguageVersion = useStoreActions((actions) => actions.model.clearCurrentLanguageVersion)
    const setCurrentLanguageVersion = useStoreActions((actions) => actions.model.setCurrentLanguageVersion)
    const changeDialogState = useStoreActions((actions) => actions.importModel.changeDialogState)
    const setSelectedPagesMultiEdit = useStoreActions((actions) => actions.multiPageEditModel.setSelectedPages)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)
    const loadHistory = useStoreActions((actions) => actions.model.loadPageHistory)

    //internal state
    const [viewMode, setViewMode] = useState<PageTreeViewMode>(PageTreeViewMode.PAGETREE)
    const [multiCreateBatchURLLinkIndex, setMultiCreateBatchURLLinkIndex] = useState(-1)
    const [expandedPageLinkSelector, setExpandedPageLinkSelector] = useState<string[]>([])

    const [pageElements, setPageElements] = useState<PageElement[]>([])
    const [selectedVersion, setSelectedVersion] = useState<PageVersion>()

    // set version when we switch page
    useEffect(() => {
        setSelectedVersion(currentlySelectedPage?.currentVersion)
    }, [currentlySelectedPage?.id, currentlySelectedPage?.currentVersion])

    // set page elements when the contents change
    useEffect(() => setPageElements(getParsedPageElements(selectedVersion?.contents)), [selectedVersion?.contents])

    // set page elements when we click on view for a previous version
    const showPageVersion = (version: PageVersion) => currentlySelectedPage && setSelectedVersion(version)

    //functions
    // add a new page version with the old contents
    const reactivatePageVersion = (version: PageVersion) => {
        if (currentlySelectedPage) {
            savePage({ pageID: currentlySelectedPage.id, content: version.contents })
            setSelectedVersion(version)
        }
    }

    const selectPage = (id: string) => {
        if (currentlySelectedPage === undefined || (currentlySelectedPage && currentlySelectedPage.id !== id)) {
            loadPage(id)
        }
    }

    const onDeletePage = (id: string) => {
        if (confirm(t('pages.pageTreePage.confirmDeletePage'))) {
            deletePage(id)
        }
    }

    const onDeactivatePage = (id: string) => {
        deactivatePage(id)
    }

    const onDuplicatePage = (id: string) => {
        duplicatePage({ pageID: id })
    }

    const onSavePage = (pageSettings: PageSettingInput) => {
        if (currentlySelectedPage)
            savePage({
                pageID: currentlySelectedPage.id,
                pageSettings,
            })
        setViewMode(PageTreeViewMode.PAGETREE)
    }

    const onEditPage = () => {
        if (currentlySelectedPage) {
            startEditingPage({
                currentlyEditingData: {
                    id: currentlySelectedPage.id,
                    identifier: '',
                    label: currentlySelectedPage.currentVersion.pageSettings.htmlTitle,
                    contents: currentlySelectedPage.currentVersion.contents,
                    type: EditingDataType.PAGE,
                },
                pageElementContents: getParsedPageElements(currentlySelectedPage.currentVersion.contents),
            })
            navigate('/PageEdit')
        }
    }

    const handleClosePageLinkSelector = () => {
        if (viewMode === PageTreeViewMode.MULTI_PAGE_LINK_SELECTOR) {
            setViewMode(PageTreeViewMode.MULTI_ADD)
            setMultiCreateBatchURLLinkIndex(-1)
        } else {
            setViewMode(PageTreeViewMode.EDIT)
        }
    }

    const handleOnSelectPages = (id: string) => {
        if (viewMode === PageTreeViewMode.MULTI_PAGE_LINK_SELECTOR) {
            updateMultiPageURLLink({ index: multiCreateBatchURLLinkIndex, id, pageList })
            setViewMode(PageTreeViewMode.MULTI_ADD)
            setMultiCreateBatchURLLinkIndex(-1)
        } else {
            setViewMode(PageTreeViewMode.EDIT)
            updateCurrentLanguageVersion(id)
        }
    }

    // ----------------------------------------------------------------------- Loading until webiste and cms data are loaded
    if (!selectedWebsite || !loggedInUser) {
        return (
            <Box sx={{ display: 'flex' }}>
                <LoadingSpinner loading={true} />
            </Box>
        )
    }

    if (viewMode === PageTreeViewMode.PAGETREE) {
        // TODO: the way I did this is a quick hack. I am not sure what the best way is - if a page has only 5 different
        // view modes then I guess putting them all into one file is ok. There is also something to watch out for, if
        // the DrawerGrid is unmounted and remounted (this happened when I was using routes to switch between the
        // different view modes by splitting this page into several pages) then the transitions do not work.
        // I guess we could have one view mode as a separate file and just do the switch here or actually leave it all in here but add properly typed accessors for elements[DrawerGrid.primaryPanel] (can we do some typescript typeof magic to get the fields of the drawerGridProps?)

        // ------------------------------------------------------------------------- page tree
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        if (currentlySelectedPage) {
            elements['secondary'] = (
                <PageDetailsPanel
                    page={currentlySelectedPage}
                    onEditPage={onEditPage}
                    onEditPageSettings={() => {
                        setViewMode(PageTreeViewMode.EDIT)
                        setCurrentLanguageVersion(currentlySelectedPage.id)
                    }}
                    onDeactivate={onDeactivatePage}
                    onDuplicate={onDuplicatePage}
                    onDelete={onDeletePage}
                    openExportDialiog={() => changeDialogState({ openDialog: true, type: ImportType.PAGE })}
                    showPageVersion={showPageVersion}
                    reactivatePageVersion={reactivatePageVersion}
                    loadHistory={loadHistory}
                    historyMap={historyMap}
                    selectedVersion={selectedVersion}
                />
            )
        }

        elements['collapsible'] = true
    }
    // ------------------------------------------------------------------------- sort view
    else if (viewMode === PageTreeViewMode.SORT) {
        elements['primary'] = (
            <PageSortPanel
                list={pageList}
                onCancel={() => setViewMode(PageTreeViewMode.PAGETREE)}
                savePageOrder={(elems: OrderItemInput[]) => {
                    savePageOrder(elems)
                    setViewMode(PageTreeViewMode.PAGETREE)
                }}
            />
        )

        elements['primaryWidth'] = DrawerWidth.MEDIUM
    }

    // ------------------------------------------------------------------------- edit view
    else if (viewMode === PageTreeViewMode.EDIT) {
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        if (currentlySelectedPage) {
            elements['secondary'] = (
                <PageSettingsEditPanel
                    saveIsDisabled={savingCurrentVersionDisabled}
                    pageSettings={currentlySelectedPage.currentVersion.pageSettings}
                    isTemplate={currentlySelectedPage.isTemplate}
                    onCancel={() => {
                        setViewMode(PageTreeViewMode.PAGETREE)
                        clearCurrentLanguageVersion()
                    }}
                    onSubmit={onSavePage}
                    domain={selectedWebsite.productionURL}
                    pagePath={resolvePageURL(currentlySelectedPage.id, pageList, true)}
                    openPageLinkSelector={() => setViewMode(PageTreeViewMode.PAGE_LINK_SELECTOR)}
                    languageVersion={currentLanguageVersion}
                    removeCurrentLanguageVersion={removeCurrentLanguageVersion}
                />
            )
        }
        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    // ------------------------------------------------------------------------- page link selector view
    else if (
        viewMode === PageTreeViewMode.PAGE_LINK_SELECTOR ||
        viewMode === PageTreeViewMode.MULTI_PAGE_LINK_SELECTOR
    ) {
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        elements['secondary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={handleOnSelectPages}
                cancel={handleClosePageLinkSelector}
                selectedPage={''}
                expanded={expandedPageLinkSelector}
                onSetExpanded={setExpandedPageLinkSelector}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    // ------------------------------------------------------------------------- add view
    else if (viewMode === PageTreeViewMode.ADD) {
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        elements['secondary'] = (
            <PageCreatePanel
                domain={selectedWebsite.productionURL}
                pagePath={resolvePageURL(currentlySelectedPage?.id ?? '', pageList) + '/'}
                parentPage={currentlySelectedPage}
                onCancel={() => setViewMode(PageTreeViewMode.PAGETREE)}
                onSubmit={(url: string, title: string, parenPage: Page | null) => {
                    const createPageInput: CreatePageInput = {
                        title,
                        url,
                        websiteID: selectedWebsite.id,
                    }
                    if (parenPage) {
                        createPageInput.parentPageID = parenPage.id
                    }
                    createPage(createPageInput)
                    setViewMode(PageTreeViewMode.PAGETREE) //TODO find a better way of closing the panel after creating
                }}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
        // ------------------------------------------------------------------------- multi add view
    } else if (viewMode === PageTreeViewMode.MULTI_ADD) {
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        elements['secondary'] = (
            <MultiPageCreatePanel
                onCancel={() => setViewMode(PageTreeViewMode.PAGETREE)}
                openPageLinkSelector={(templateInfoIndex: number) => {
                    setMultiCreateBatchURLLinkIndex(templateInfoIndex)
                    setViewMode(PageTreeViewMode.MULTI_PAGE_LINK_SELECTOR)
                }}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    } else if (viewMode === PageTreeViewMode.MULTI_EDIT) {
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        elements['secondary'] = (
            <MultiEditPagePanel
                onCancel={() => setViewMode(PageTreeViewMode.PAGETREE)}
                openPageLinkSelector={() => setViewMode(PageTreeViewMode.MULTI_EDIT_PAGE_LINK_SELECTOR)}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    // ------------------------------------------------------------------------- multi page link selector view
    else if (viewMode === PageTreeViewMode.MULTI_EDIT_PAGE_LINK_SELECTOR) {
        elements['primary'] = (
            <PageTreePanel
                pages={pageList}
                onSelectPage={selectPage}
                onPageCreate={() => setViewMode(PageTreeViewMode.ADD)}
                onMultiCreate={() => setViewMode(PageTreeViewMode.MULTI_ADD)}
                onMultiEdit={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                switchToSortMode={() => setViewMode(PageTreeViewMode.SORT)}
                selectedPage={currentlySelectedPage ? currentlySelectedPage.id : ''}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
        )

        elements['secondary'] = (
            <MultiSelectPageTreePanel
                pages={pageList}
                selectedPages={selectedPagesMultiEdit}
                cancel={() => setViewMode(PageTreeViewMode.MULTI_EDIT)}
                saveSelectedPages={(ids: string[]) => {
                    setViewMode(PageTreeViewMode.MULTI_EDIT)
                    setSelectedPagesMultiEdit(ids)
                }}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    return (
        <>
            <LoadingSpinner loading={isMultiPageCreateLoading || isMultiPageEditLoading} />
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid
                {...elements}
                collapsible
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        blocks={blockData}
                        disabled={true}
                        pageElements={pageElements}
                        activeBreakpoint={{
                            identifier: 'xl',
                            name: 'xl',
                            editorCssWidth: '100%',
                            fromWidthPixels: 1536,
                        }}
                    />
                }
            />
            <ExportImportDialog />
        </>
    )
}

export default PageTreePage
