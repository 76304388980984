import { Delete, Edit, FileCopy, History, Settings } from '@mui/icons-material'
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import VersionHistory, { HistoryVersion } from 'components/VersionHistory'
import { format } from 'date-fns'
import { Block, BlockVersion, State } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface BlockDetailsPanelrops {
    onEditBlock: (block: Block) => void
    onEditSettings: (blockID: string) => void
    onDuplicate: (blockID: string) => void
    onDelete: (blockID: string) => void
    openExportDialog: () => void
    showBlockVersion: (version: BlockVersion) => void
    reactivateBlockVersion: (version: BlockVersion) => void
    loadHistory: (id: string) => void
    block: Block
    historyMap: Record<string, BlockVersion[]>
    selectedVersion: BlockVersion | undefined
}

const timeFormat = 'dd.MM.yyyy HH:mm'

const getLastPublished = (block: Block): string => {
    return block.lastPublished ? format(new Date(block.lastPublished), timeFormat) : '-'
}

const BlockDetailsPanel = (props: BlockDetailsPanelrops): JSX.Element => {
    const {
        onEditBlock,
        onEditSettings,
        onDuplicate,
        onDelete,
        reactivateBlockVersion,
        showBlockVersion,
        loadHistory,
        openExportDialog,
        block,
        historyMap,
        selectedVersion,
    } = props

    const { t } = useTranslation()

    const lastPublished = getLastPublished(block)

    const [displayBlockVersion, setDisplayBlockVersion] = useState<boolean>(false)

    useEffect(() => {
        setDisplayBlockVersion(false)
    }, [block.id])

    const showHistory = () => {
        setDisplayBlockVersion(!displayBlockVersion)

        // map.has but with record, since maps are not serializeable and thus should not be used in redux
        if (!Object.keys(historyMap).some((key) => key === block.id)) loadHistory(block.id)
    }

    const versions: HistoryVersion<BlockVersion>[] =
        historyMap[block.id]?.map((version) => ({
            id: version.id,
            published: version.state === State.PUBLISHED,
            timestamp: version.updatedAt,
            user: version.updatedBy.name,
            item: version,
        })) ?? []

    return (
        <Grid container direction="column" p={2} gap={1}>
            <Grid container item direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                    <Typography variant="h6">{block.currentVersion.name}</Typography>
                </Grid>
                <Grid item xs={2} justifyItems={'flex-end'}>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.edit')} placement="right">
                        <IconButton
                            onClick={() => {
                                onEditBlock(block)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container item direction="row">
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.lastChange')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.lastChange')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    {format(new Date(block.currentVersion.updatedAt), timeFormat)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.changedBy')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.changedBy')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">
                                    <Tooltip title={block.currentVersion.updatedBy.name} placement="left">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '130px',
                                                marginLeft: 'auto',
                                            }}
                                        >
                                            {block.currentVersion.updatedBy.name}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.lastPublish')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            {t('drawerPanels.blockDetailsPanel.lastPublish')}
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{lastPublished}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell scope="row">
                                    <Tooltip title={t('drawerPanels.blockDetailsPanel.status')} placement="right">
                                        <Typography
                                            variant="body2"
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                maxWidth: '74px',
                                            }}
                                        >
                                            St<a onClick={openExportDialog}>a</a>tus
                                        </Typography>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="right">{t(`common.${block.currentVersion.state}`)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid container item direction="row">
                <Grid item xs={10} display="flex" alignContent="end">
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.settings')}>
                        <IconButton
                            onClick={() => {
                                onEditSettings(block.id)
                            }}
                        >
                            <Settings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.duplicate')}>
                        <IconButton
                            onClick={() => {
                                onDuplicate(block.id)
                            }}
                        >
                            <FileCopy />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.delete')}>
                        <IconButton
                            onClick={() => {
                                onDelete(block.id)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('drawerPanels.blockDetailsPanel.versionHistory')}>
                        <IconButton onClick={showHistory}>
                            <History />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {displayBlockVersion && (
                <VersionHistory<BlockVersion>
                    versions={versions}
                    currentlyShowingVersion={selectedVersion?.id ?? ''}
                    reactivate={(version) => reactivateBlockVersion(version.item)}
                    show={(version) => showBlockVersion(version.item)}
                />
            )}
        </Grid>
    )
}

export default BlockDetailsPanel
