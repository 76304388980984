import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material'
import { ImportDataInput, ImportType } from 'graphql/types'
import { useState } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'

const ExportImportDialog = (): JSX.Element => {
    const [dataContent, setDataContent] = useState('')

    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const error = useStoreState((state) => state.importModel.error)
    const isLoading = useStoreState((state) => state.importModel.isLoading)
    const importDialogOpen = useStoreState((state) => state.importModel.importDialogOpen)
    const importType = useStoreState((state) => state.importModel.importType)
    const currentlySelectedBlock = useStoreState((state) => state.model.currentlySelectedBlock)
    const currentlySelectedPage = useStoreState((state) => state.model.currentlySelectedPage)
    const assets = useStoreState((state) => state.model.assets)
    const folders = useStoreState((state) => state.model.folders)

    const setError = useStoreActions((actions) => actions.importModel.setError)
    const changeDialogState = useStoreActions((actions) => actions.importModel.changeDialogState)
    const importData = useStoreActions((actions) => actions.importModel.importData)

    const fromJSON = () => {
        setError('')
        if (dataContent.trim().length == 0) {
            setError('You have to enter valid json')
            return
        }
        try {
            const data: ImportDataInput = JSON.parse(dataContent)
            if (!data.content || !data.type || !data.content.content) {
                setError('You have to enter valid json')
                return
            }

            data.websiteID = selectedWebsite?.id ?? ''

            importData(data)
        } catch (e) {
            setError('You have to enter valid json. error: ' + e)
        }
    }
    const toJSON = () => {
        setError('')

        if (importType == '') {
            setError('No import type selected')
            return
        }

        // set export data
        const data: ImportDataInput = {
            websiteID: '',
            content: {
                changeFrequency: '',
                content: '',
                identifier: '',
                includeInSitemapXML: false,
                language: '',
                metaDescription: '',
                metaKeywords: '',
                name: '',
                pageRank: '',
                robotsOption: '',
                urlTitle: '',
            },
            media: [],
            type: importType,
        }

        if (importType === ImportType.BLOCK && currentlySelectedBlock) {
            const content = currentlySelectedBlock.currentVersion

            data.content.content = content.contents
            data.content.name = content.name
            data.content.identifier = currentlySelectedBlock.identifier
        } else if (currentlySelectedPage) {
            const content = currentlySelectedPage.currentVersion

            data.content.content = content.contents
            data.content.name = content.pageSettings.htmlTitle
            data.content.urlTitle = content.pageSettings.urlTitle
            data.content.changeFrequency = content.pageSettings.changeFrequency
            data.content.includeInSitemapXML = content.pageSettings.includeInSitemapXML
            data.content.language = content.pageSettings.language
            data.content.metaDescription = content.pageSettings.metaDescription
            data.content.metaKeywords = content.pageSettings.metaKeywords
            data.content.pageRank = content.pageSettings.pageRank
            data.content.robotsOption = content.pageSettings.robotsOption
        } else {
            setError('There is no page data. Please check.')
            return
        }

        //get assets
        const match = data.content.content.match(/\"Asset\/(\d*)\"/g)

        if (match) {
            for (const asset of match) {
                //remove "
                const fileNameResolved = asset.replaceAll('"', '')

                //find asset
                const foundFile = assets.find((a) => a.id == fileNameResolved)

                if (!foundFile) {
                    setError('Could not find file in assets: ' + asset)
                    continue
                }

                //find folder path
                let folderPath = ''
                if (foundFile.folder) {
                    //find file folder
                    const fileFolder = folders.find((f) => f.id == foundFile.folder)
                    if (!fileFolder) {
                        setError('Could not find folder: ' + foundFile.folder)
                        return
                    }

                    //get from fileFolder the path
                    folderPath += fileFolder.name
                    if (fileFolder.parentFolder != '') {
                        let currentParentID = fileFolder.parentFolder
                        while (currentParentID != '') {
                            const folder = folders.find((f) => f.id == currentParentID)
                            if (folder) {
                                folderPath = folder.name + '/' + folderPath
                                currentParentID = folder.parentFolder
                            }
                        }
                    }
                }

                data.media.push({
                    id: foundFile.id,
                    type: foundFile.type,
                    name: foundFile.name,
                    folderPath: folderPath,
                    url: foundFile.url,
                })
            }
        }

        //set content
        setDataContent(JSON.stringify(data))
    }

    const onClose = () => {
        setDataContent('')
        changeDialogState({ openDialog: false, type: '' })
    }

    const onUpdateData = (e: any) => {
        setDataContent(e.target.value)
    }

    const titleInfo = 'Import & Export' + (importType == ImportType.BLOCK ? ' Block' : ' Page')

    return (
        <Dialog
            disableEscapeKeyDown={false}
            aria-labelledby="customized-dialog-title"
            open={importDialogOpen}
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle id="customized-dialog-title">{titleInfo}</DialogTitle>
            <DialogContent style={{ padding: '24px' }} dividers>
                {isLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!isLoading && (
                    <>
                        {error != '' && (
                            <Typography
                                variant="body2"
                                color={'error'}
                                style={{ marginBottom: 10, textAlign: 'center' }}
                            >
                                {error}
                            </Typography>
                        )}
                        <TextField
                            rows={25}
                            onChange={onUpdateData}
                            value={dataContent}
                            id="filled-textarea"
                            label="JSON content data"
                            fullWidth
                            placeholder="JSON Date from the selected content"
                            multiline
                            variant="filled"
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={toJSON} disabled={isLoading}>
                    To JSON
                </Button>
                <Button variant="outlined" onClick={fromJSON} disabled={isLoading}>
                    from JSON
                </Button>
                <Button variant="contained" color="error" onClick={onClose} disabled={isLoading}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ExportImportDialog
