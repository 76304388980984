import { Check, Edit, Search, SettingsBackupRestore } from '@mui/icons-material'
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react/jsx-runtime'
import { formatDateNumberToString } from 'utils/date'

export interface VersionHistoryProps<T> {
    versions: HistoryVersion<T>[]
    currentlyShowingVersion: string // id of the id that is currently displayed in the UI

    show: (version: HistoryVersion<T>) => void
    reactivate: (version: HistoryVersion<T>) => void
}

export interface HistoryVersion<T> {
    id: string
    timestamp: number // unix seconds
    user: string // name user that was responsible for this version
    published: boolean
    tooltip?: string // show tooltip if set

    item: T // the actual item so that we dont have to go lookup by id afterwards when using this
}

const VersionHistory = <T extends unknown = any>(props: VersionHistoryProps<T>): JSX.Element => {
    const { reactivate, show, versions, currentlyShowingVersion } = props

    const { t } = useTranslation()

    return (
        <Stack>
            <Typography variant="h5">{t('components.versionHistory.versions')}</Typography>
            {versions.map((version, index) => (
                <Fragment key={version.id}>
                    {index !== 0 && <Divider />}
                    <Stack direction={'row'} paddingY={0.5} alignItems={'center'}>
                        <Tooltip
                            title={t(`common.${version.published ? 'PUBLISHED' : 'UNPUBLISHED'}`)}
                            placement="left"
                        >
                            <span>
                                {version.published && <Check />}
                                {!version.published && <Edit />}
                            </span>
                        </Tooltip>
                        <Tooltip title={version.tooltip} placement="left">
                            <Stack flex={1} marginX={1}>
                                <Typography>{formatDateNumberToString(version.timestamp, 'en')}</Typography>
                                <Typography
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        maxWidth: '150px',
                                    }}
                                >
                                    {version.user}
                                </Typography>
                            </Stack>
                        </Tooltip>

                        <Tooltip title={t('components.versionHistory.show')} placement="left">
                            <IconButton onClick={() => show(version)}>
                                <Search color={version.id === currentlyShowingVersion ? 'primary' : 'action'} />
                            </IconButton>
                        </Tooltip>
                        {index !== 0 && (
                            <Tooltip title={t('components.versionHistory.reactivate')} placement="right">
                                <IconButton onClick={() => reactivate(version)}>
                                    <SettingsBackupRestore />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </Fragment>
            ))}
        </Stack>
    )
}

export default VersionHistory
