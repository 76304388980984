import { StoreProvider } from 'easy-peasy'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import store from './store'

const rootElement = document.getElementById('root')
//NOTE: root is always there we know that otherwise the compile will pop a warning
//eslint-disable-next-line
const root = createRoot(rootElement!)

// StrictMode breaks beatiful dnd https://github.com/atlassian/react-beautiful-dnd/issues/2396
root.render(
    <StoreProvider store={store}>
        <App />
    </StoreProvider>,
)
