const colors = {
    black: '#000000',
    black06Opacity: 'rgba(0, 0, 0, 0.6)',
    black03Opacity: 'rgba(0, 0, 0, 0.3)',
    darkGray: '#929292',
    lightGray: '#F0F0F0',
    midGray: '#C4C4C4',
    lightBlue: '#C4C4C4',
    blue: '#0091ff',
    white: '#FFFFFF',
    whiteHalfOpacity: 'rgba(255, 255, 255, 0.5)',
    whiteLowOpacity: 'rgba(255, 255, 255, 0.3)',
    main: '#CB4700',
    warning: '#EB5757',
    success: '#30b531',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    yellow: '#FFEB3B',
    orange: '#FF9700',
    red: '#FF0000',
    primary: '#5eafc7',
    primaryHalfOpacity: 'rgba(94, 175, 199, 0.5)',
    primaryLowOpacity: 'rgba(94, 175, 199, 0.3)',
    secondary: '#cccccc',
    buttons: {
        normal: '#5eafc7',
        normalHover: 'rgba(94, 175, 199, 0.5)',
        beige: '#F2F4EF',
        beigeHover: '#5eafc7',
        white: '#FFFFFF',
        whiteHover: '#5eafc7',
        whiteSelected: '#5eafc7',
        disabled: '#D6D8D3',
    },
}

export default colors
