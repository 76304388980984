import { MutationHookOptions, gql, useMutation } from '@apollo/client'
import {
    AttributeCollectionValueItem,
    DataItem,
    DataItemValues,
    DataTypeAttributeType,
    DataTypeAttributes,
    Mutation,
} from 'graphql/types'
import _ from 'lodash'
import {
    AttributeInputUnion,
    AttributeTypename,
    AttributeUnion,
    AttributeValueUnion,
    DataItemUnion,
    DataTypeCreateAttributeArgs,
    DataTypeCreateAttributeHook,
    DataTypeUpdateAttributeArgs,
    DataTypeUpdateAttributeHook,
    MutationAttributeInputUnion,
    createDataItemUnionFromValues,
    createDefaultDataTypeAttributes,
} from './types'

// Takes a DataType
// Returns all attributes in the correct order as an array of union types
// That can be discriminated later on __typename
export const getOrderedAttributeUnion = (attributes: DataTypeAttributes): AttributeUnion[] => {
    const result: AttributeUnion[] = []
    let current = attributes._head
    // iterate through linked list
    linkedListLoop: while (current) {
        // iterate through all typed lists
        for (const key in attributes) {
            // which are all keys of the dataType that start with "attribute"
            if (key.startsWith('attribute')) {
                const list = attributes[key as keyof DataTypeAttributes] as AttributeUnion[]

                // iterate through all items of that list
                for (const attribute of list) {
                    if (attribute.id === current) {
                        // push attribute to its proper position
                        result.push(attribute)
                        // set the next id
                        current = attribute.common._next

                        // then finally continue building the list with the next id
                        continue linkedListLoop
                    }
                }
            }
        }

        throw new Error(
            `infinite loop detected in getOrderedAttributeUnion, faulty input data. Last attribute ID was ${current}`,
        )
    }

    return result
}

// Returns the discriminated (server side representation) version of an attribute union
// aka reverse of getOrderedAttributeUnion
export const getDiscriminatedAttributesFromUnion = (attributes: AttributeUnion[]): DataTypeAttributes => {
    const discriminated = _.cloneDeep(createDefaultDataTypeAttributes()) as Record<keyof DataTypeAttributes, any>

    discriminated._head = attributes.at(0)?.id

    // Iterate through all attribute lists
    for (const key in discriminated) {
        // which are all keys of the dataType that start with "attribute"
        if (key.startsWith('attribute')) {
            const list = attributes.filter((attribute) =>
                key.toLowerCase().includes(attribute.__typename?.toLowerCase() ?? ''),
            )

            discriminated[key as keyof DataTypeAttributes] = list
        }
    }
    return discriminated as DataTypeAttributes
}

// this batch creates creation hooks (loading + hook)
// just call the returned function with
// the dataTypeID
// and an input union (discriminated)
export const useDataTypeCreateAttributeMutation = (
    options?: Omit<MutationHookOptions, 'variables'>,
): [(dataTypeID: string, input: AttributeInputUnion) => void, boolean] => {
    // Create an anonymous hook for each create mutation
    const hooks: DataTypeCreateAttributeHook[] = Object.values(DataTypeAttributeType).map((type) => {
        // Makes e.g. "TEXT" into "Text"
        const string = type.charAt(0) + type.toLowerCase().slice(1)
        const graphql = gql`
            mutation dataTypeCreateAttribute${string}($dataTypeID: ID!, $input: Attribute${string}Input!) {
                dataTypeCreateAttribute${string}(dataTypeID: $dataTypeID, input: $input)
            }
        `
        // eslint-disable-next-line react-hooks/rules-of-hooks -- constant list map, works just fine
        const mutation = useMutation<Mutation, DataTypeCreateAttributeArgs>(graphql, options)
        return { type, mutation }
    })

    const anyLoading = hooks.some((hook) => hook.mutation[1].loading)

    return [
        (dataTypeID: string, attributeInput: AttributeInputUnion) => {
            for (const hook of hooks) {
                if (hook.type === attributeInput._type) {
                    const [create] = hook.mutation
                    const input = _.omit(attributeInput, ['_type', 'common._next']) as MutationAttributeInputUnion
                    create({ variables: { dataTypeID, input } })
                    return
                }
            }
        },
        anyLoading,
    ]
}
// this batch creates update hooks
// just call the returned function with
// an input union (discriminated)
export const useDataTypeUpdateAttributeMutation = (
    options?: Omit<MutationHookOptions, 'variables'>,
): [(input: AttributeInputUnion) => void, boolean] => {
    // Create an anonymous hook for each update mutation
    const hooks: DataTypeUpdateAttributeHook[] = Object.values(DataTypeAttributeType).map((type) => {
        // Makes e.g. "TEXT" into "Text"
        const string = type.charAt(0) + type.toLowerCase().slice(1)
        const graphql = gql`
            mutation dataTypeUpdateAttribute${string}($input: Attribute${string}Input!) {
                dataTypeUpdateAttribute${string}(input: $input)
            }
        `
        // eslint-disable-next-line react-hooks/rules-of-hooks -- constant list map, works just fine
        const mutation = useMutation<Mutation, DataTypeUpdateAttributeArgs>(graphql, options)
        return { type, mutation }
    })

    const anyLoading = hooks.some((hook) => hook.mutation[1].loading)

    return [
        (attributeInput: AttributeInputUnion) => {
            for (const hook of hooks) {
                if (hook.type === attributeInput._type) {
                    const [update] = hook.mutation
                    const input = _.omit(attributeInput, ['_type', 'common._next']) as MutationAttributeInputUnion
                    update({ variables: { input } })
                    return
                }
            }
        },
        anyLoading,
    ]
}

// Takes a DataType
// And a DataItem
// Then returns the values in the order defined in the object
// which can later be discriminated individually on __typename
// NOTE: the order of the items in the value is completely irrelevant,
// as the final list gets built from ONLY the order of the object
export const getOrderedAttributeValueUnion = (
    attributes: AttributeUnion[],
    value: DataItemValues | AttributeCollectionValueItem,
): AttributeValueUnion[] => {
    const values: AttributeValueUnion[] = []

    // find the value for all attributes
    // this does NOT handle missing attributes, they are just assumed to be consistent or otherwise handled
    for (const attribute of attributes) {
        for (const key in value) {
            if (key.startsWith('attribute')) {
                const list = (value as any)[key] as AttributeValueUnion[]

                for (const item of list) {
                    if (item.attributeID === attribute.id) {
                        values.push(item)
                    }
                }
            }
        }
    }
    return values
}

// faster and more compact version of getOrderedAttributeValueUnion
// for use with precomputed attribute list and a list of items
// returns a ready to use list of dataItems with union values
export const getDataItemUnionList = (attributes: AttributeUnion[], items: DataItem[]): DataItemUnion[] => {
    return items.map((item) => {
        const values: AttributeValueUnion[] = []

        // find the value for all attributes
        // this does NOT handle missing attributes, they are just assumed to be consistent or otherwise handled
        for (const attribute of attributes) {
            let key: keyof DataItemValues
            for (key in item.values) {
                if (key.startsWith('attribute')) {
                    const list = item.values[key] as AttributeValueUnion[]

                    for (const item of list) {
                        if (item.attributeID === attribute.id) {
                            values.push(item)
                        }
                    }
                }
            }
        }
        return createDataItemUnionFromValues(item, values)
    })
}

// Takes attributes: DataItemUnion
// Then creates a DataItemValues with attributes copied into their proper fields
export const getDiscriminatedValuesFromAttributeValueUnion = (values: AttributeValueUnion[]): DataItemValues => {
    const discriminated: Record<keyof DataItemValues, any> = {
        attributeAssetValues: [],
        attributeBooleanValues: [],
        attributeCollectionValues: [],
        attributeLinkValues: [],
        attributeNumberValues: [],
        attributeSelectionValues: [],
        attributeTextValues: [],
        attributeDateValues: [],
        __typename: 'DataItemValues',
    }

    // Iterate through all attribute lists
    for (const key in discriminated) {
        // which are all keys of the object that start with "attribute"
        if (key.startsWith('attribute')) {
            const list = values.filter((attribute) =>
                key.toLowerCase().includes(attribute.__typename?.toLowerCase() ?? ''),
            )
            discriminated[key as keyof DataItemValues] = list
        }
    }

    return discriminated
}

// Takes an item: DataItemUnion
// Then creates a DataItem with attributes copied into their proper fields
// same as above just wrapper for entire item
export const getDiscriminatedItemFromDataItemUnion = (item: DataItemUnion): DataItem => {
    const values = getDiscriminatedValuesFromAttributeValueUnion(item.values)

    return {
        id: item.id,
        published: item.published,
        enabled: item.enabled,
        created: item.created,
        updated: item.updated,
        values,
        __typename: 'DataItem',
    }
}

export const getTypenameFromAttributeType = (attributeType: DataTypeAttributeType): AttributeTypename =>
    `Attribute${attributeType.charAt(0) + attributeType.toLowerCase().slice(1)}` as AttributeTypename

export const getAttributeTypeFromTypename = (typename: AttributeTypename): DataTypeAttributeType =>
    typename.replace('Attribute', '').toUpperCase() as DataTypeAttributeType
