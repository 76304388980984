import { Box, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'store/hooks'
import getEnvConfig from 'utils/getEnvConfig'
import { getKeycloakInstance, initKeycloak } from './Auth'

export interface AuthWrapperProps {
    children: JSX.Element
}

const AuthWrapper = (props: AuthWrapperProps): JSX.Element => {
    const env = getEnvConfig()
    const instance = getKeycloakInstance()

    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const loadCMSData = useStoreActions((actions) => actions.model.loadCMSData)

    const [keycloakIsLoading, setKeycloakIsLoading] = useState<boolean>(env.Keycloak.Enabled)

    useEffect(() => {
        if (env.Keycloak.Enabled) {
            initKeycloak()
                .then((k) => {
                    k.loadUserProfile()
                        .then(() => {
                            setKeycloakIsLoading(false)
                        })
                        .catch((e) => {
                            console.error('Failed to load Keycloak profile:', e)
                            setKeycloakIsLoading(false)
                        })
                })
                .catch((e) => {
                    console.error('Failed to initialize Keycloak:', e)
                    setKeycloakIsLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Load CMS Data
    useEffect(() => {
        if (!loggedInUser) {
            if (env.Keycloak.Enabled && !keycloakIsLoading && instance.profile) {
                loadCMSData({
                    email: instance.profile.email || '',
                    firstName: instance.profile.firstName || '',
                    lastName: instance.profile.lastName || '',
                })
            } else if (!env.Keycloak.Enabled) {
                loadCMSData({
                    email: '',
                    firstName: '',
                    lastName: '',
                })
            }
        }
    }, [loadCMSData, loggedInUser, env.Keycloak.Enabled, instance.profile, keycloakIsLoading])

    if (keycloakIsLoading) {
        return (
            <Box width={'100vw'} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <CircularProgress />
            </Box>
        )
    } else {
        return props.children
    }
}

export default AuthWrapper
