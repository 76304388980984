import { OperationVariables } from '@apollo/client'
import { getKeycloakInstance } from 'components/AuthWrapper/Auth'
import { DocumentNode } from 'graphql'
import { Query } from 'graphql/types'
import getEnvConfig, { getGraphQLServerURI } from 'utils/getEnvConfig'

export interface FetchResult<Result = any> {
    errors: FetchError[]
    data: Result
}
export interface FetchError {
    message: string
    path: string[]
}

export const fetchData = async <TData = Query, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode,
    variables: TVariables,
) => {
    if (!query.loc) return
    const env = getEnvConfig()
    const instance = getKeycloakInstance()

    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }

    if (env.Keycloak.Enabled) {
        headers['Authorization'] = `Bearer ${instance.idToken}`
    }

    try {
        // TODO: it may be neccessary to do const d = new Date() + d.getTime()
        const response = await fetch(getGraphQLServerURI(), {
            method: 'POST',
            headers: headers,
            credentials: 'include',
            body: JSON.stringify({ query: query.loc.source.body, variables }),
        })

        if (response?.status === 401) {
            if (env.Keycloak.Enabled) {
                window.location.href = '/'
            } else {
                window.location.href = '/#/NotLoggedIn'
            }
            return undefined
        }
        if (!response) return undefined
        const data = (await response.json()) as FetchResult<TData>
        return data
    } catch (e) {
        console.log(e)
    }
}
