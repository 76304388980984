import { format, formatDistanceToNowStrict } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

export const formatDateNumberToString = (date: number, language: 'en' | 'de'): string =>
    format(new Date(date * 1000), 'dd.MM.yyyy HH:mm', { locale: language === 'en' ? enUS : de })

export const formatDateNumberToDistanceToNow = (
    date: number,
    language: 'en' | 'de',
    addSuffix?: boolean | undefined,
): string => formatDistanceToNowStrict(new Date(date * 1000), { locale: language === 'en' ? enUS : de, addSuffix })
