import gql from 'graphql-tag'

export const LOAD_PAGE_HISTORY = gql`
    query loadPageHistory($pageID: ID!) {
        loadPageHistory(pageID: $pageID) {
            id
            pageID
            state
            contents
            createdAt
            updatedAt
            updatedBy {
                id
                name
            }
            pageSettings {
                htmlTitle
                urlTitle
                metaDescription
                metaKeywords
                robotsOption
                includeInSitemapXML
                pageRank
                changeFrequency
                language
                translationID
            }
        }
    }
`

export const LOAD_BLOCK_HISTORY = gql`
    query loadBlockHistory($blockID: ID!) {
        loadBlockHistory(blockID: $blockID) {
            id
            blockID
            state
            contents
            name
            createdAt
            updatedAt
            updatedBy {
                id
                name
            }
        }
    }
`
