import Keycloak from 'keycloak-js'
import getEnvConfig from 'utils/getEnvConfig'

const env = getEnvConfig()

const keycloakConfig = {
    url: env.Keycloak.URL,
    realm: env.Keycloak.Realm,
    clientId: env.Keycloak.Client,
}

let keycloakInstance: Keycloak | null = null
let isInitialized = false

export const getKeycloakInstance = (): Keycloak => {
    if (!keycloakInstance) {
        keycloakInstance = new Keycloak(keycloakConfig)
    }
    return keycloakInstance
}

// Initialize Keycloak
export const initKeycloak = async (): Promise<Keycloak> => {
    if (!isInitialized) {
        const instance = getKeycloakInstance()
        try {
            await instance.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
            })

            isInitialized = true
            keycloakInstance = instance

            return instance
        } catch (err) {
            console.error('Failed to initialize Keycloak:', err)
            throw err
        }
    } else {
        return getKeycloakInstance()
    }
}

// Function to handle the token refresh logic.
export const refreshTokenIfNeeded = async (): Promise<boolean> => {
    const instance = getKeycloakInstance()
    if (instance.isTokenExpired(30)) {
        try {
            const refreshed = await instance.updateToken(60)
            console.log('Token was successfully refreshed:', refreshed)
            return refreshed
        } catch (error) {
            console.error('Failed to refresh token:', error)
            throw error
        }
    }
    return false
}

// Function to handle user logout.
export const logoutKeycloak = (): void => {
    const instance = getKeycloakInstance()
    instance.logout({ redirectUri: document.location.origin })
}
