import { Delete, FileCopy, History, Publish, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Divider, IconButton, Stack, TextField, Tooltip } from '@mui/material'
import VersionHistory, { HistoryVersion } from 'components/VersionHistory'
import { DataItemEditPanelProps } from 'drawerPanels/DataItemEditPanel/DataItemEditPanel'
import { DataItemVersion } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { AttributeUnion, AttributeValueUnion, DataItemUnion, DataItemVersionUnion } from 'utils/dataType/types'
import { AttributeValueEdit } from './AttributeValueEdit'

export type DataItemDetailsPanelProps = Omit<DataItemEditPanelProps, 'selectedItem' | 'selectedVersion'> & {
    selectedItem: DataItemUnion
    selectedVersion: DataItemVersionUnion
}
export type AttributeValueEditProps = DataItemDetailsPanelProps & {
    attribute: AttributeUnion | undefined
    attributeValue: AttributeValueUnion
    hideDivider?: boolean
    index: number
    disabled: boolean
}

const DataItemDetailsPanel = (props: DataItemDetailsPanelProps): JSX.Element | null => {
    const {
        onClickSaveItem,
        onClickCancelItem,
        selectedItem,
        errors,
        onClickDeleteItem,
        onClickPublish,
        onClickToggleEnabled,
        onClickDuplicateItem,
        onClickHistory,
        showHistory,
        historyMap,
        onClickShowItemVersion,
        onClickReactivateItemVersion,
        selectedVersion,
    } = props

    const { t } = useTranslation()

    const versions: HistoryVersion<DataItemVersion>[] =
        historyMap[selectedItem.id]?.map((version) => ({
            id: version.id,
            published: version.published,
            timestamp: version.updated.timestamp,
            user: version.updated.user.name,
            tooltip: t(`common.DataItemChangeType.${version.updated.changeType}`),
            item: version,
        })) ?? []

    return (
        <Stack direction="column" p={2} position={'relative'} gap={2}>
            <Stack
                direction={'column'}
                position={'sticky'}
                top={0}
                zIndex={20}
                bgcolor={colors.lightGray}
                padding={2}
                margin={-2}
                gap={1}
            >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                    <Button variant="contained" color={'secondary'} fullWidth onClick={onClickCancelItem}>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained" fullWidth onClick={onClickSaveItem} disabled={errors.length > 0}>
                        {t('common.save')}
                    </Button>
                </Stack>

                {/* show only for editing items */}
                {selectedItem.id !== '' && (
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Tooltip title={t(`common.${selectedItem.published ? 'PUBLISHED' : 'publish'}`)}>
                            <span>
                                <IconButton
                                    onClick={() => onClickPublish(selectedItem)}
                                    disabled={selectedItem.published}
                                >
                                    <Publish />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={t(
                                `drawerPanels.pageDetailsPanel.${selectedItem.enabled ? 'deactivate' : 'activate'}`,
                            )}
                        >
                            <IconButton onClick={() => onClickToggleEnabled(selectedItem)}>
                                {selectedItem.enabled && <Visibility />}
                                {!selectedItem.enabled && <VisibilityOff />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('drawerPanels.pageDetailsPanel.duplicate')}>
                            <IconButton onClick={() => onClickDuplicateItem(selectedItem)}>
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('drawerPanels.pageDetailsPanel.delete')}>
                            <IconButton onClick={() => onClickDeleteItem(selectedItem)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('drawerPanels.pageDetailsPanel.versionHistory')}>
                            <IconButton onClick={() => onClickHistory(selectedItem)}>
                                <History color={showHistory ? 'primary' : 'action'} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}

                {showHistory && <Divider />}
                {showHistory && (
                    <Stack maxHeight={3 * 56} overflow={'auto'}>
                        <VersionHistory
                            versions={versions}
                            currentlyShowingVersion={selectedVersion?.id ?? ''}
                            show={(version) => onClickShowItemVersion(version.item)}
                            reactivate={(version) => onClickReactivateItemVersion(version.item)}
                        />
                    </Stack>
                )}
            </Stack>

            <Divider />

            <TextField label={'ID'} value={selectedItem.id} disabled />
            {selectedVersion.snapshotValues.map((attributeValue) => (
                <AttributeValueEdit
                    key={attributeValue.attributeID}
                    {...props}
                    attribute={selectedVersion.snapshotAttributes.find(
                        (attribute) => attribute.id === attributeValue.attributeID,
                    )}
                    attributeValue={attributeValue}
                    index={-1}
                    disabled={showHistory} // do NOT allow editing of items while the history is open, so that we dont have weird timeline issues
                />
            ))}
        </Stack>
    )
}

export default DataItemDetailsPanel
