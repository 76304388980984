import { MutationHookOptions, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
    DATA_ITEM_CREATE,
    DATA_ITEM_DELETE,
    DATA_ITEM_PUBLISH,
    DATA_ITEM_SET_ENABLED,
    DATA_ITEM_UPDATE,
} from 'graphql/mutations/dataItem'
import { DATA_ITEM_GET_HISTORY } from 'graphql/queries/dataItemGet'
import { DATA_TYPE_GET, DATA_TYPE_GET_WITH_ITEMS } from 'graphql/queries/dataTypeGet'
import {
    Asset,
    DataItemVersion,
    DataType,
    DataTypeCategory,
    Mutation,
    MutationDataItemCreateArgs,
    MutationDataItemDeleteArgs,
    MutationDataItemPublishArgs,
    MutationDataItemSetEnabledArgs,
    MutationDataItemUpdateArgs,
    Page,
    Query,
    QueryDataItemGetHistoryArgs,
    QueryDataTypeGetArgs,
    QueryDataTypeGetWithItemsArgs,
} from 'graphql/types'
import { useDataErrors } from 'pages/DataItemPage/useDataErrors'
import { useEffect, useMemo, useState } from 'react'
import { useStoreState } from 'store/hooks'
import {
    getDataItemUnionList,
    getDiscriminatedItemFromDataItemUnion,
    getOrderedAttributeUnion,
    getOrderedAttributeValueUnion,
} from 'utils/dataType/functions'
import {
    AttributeUnion,
    AttributeUnionWithID,
    AttributeValueUnion,
    DataItemUnion,
    DataItemVersionUnion,
    createDefaultDataItem,
    createInitialAttributeValues,
    getCollectionValueItemFromAttributeValues,
    getDataItemInputFromType,
} from 'utils/dataType/types'
import { FormError, UnpublishedItem } from 'utils/types'
import { v4 } from 'uuid'

export interface UseDataItemPageReturn {
    // data
    dataTypes: DataType[]
    items: DataItemUnion[]
    attributes: AttributeUnion[] // contains all the attributes to render the overview list
    unpublishedItems: UnpublishedItem[] // pages/blocks for header
    assets: Asset[]
    pages: Page[]

    // selection
    selectedType: DataType | undefined
    setSelectedType: (item: DataType) => void
    selectedItem: DataItemUnion | undefined
    setSelectedItem: (item: DataItemUnion) => void
    selectedVersion: DataItemVersionUnion | undefined // contains all the data necessary to render the details of an item

    // history
    showHistory: boolean
    historyMap: Record<string, DataItemVersion[] | undefined>

    // actions
    onUpdateValue: (value: AttributeValueUnion) => void // updates one single attribute value in the selectedVersion
    onClickType: (item: DataType) => void
    onClickItem: (item: DataItemUnion) => void
    onClickCreateItem: () => void
    onClickDuplicateItem: (item: DataItemUnion) => void
    onClickSaveItem: () => void
    onClickCancelItem: () => void
    onClickDeleteItem: (item: DataItemUnion) => void
    onClickPublish: (item: DataItemUnion) => void
    onClickPublishAll: () => void
    onClickToggleEnabled: (item: DataItemUnion) => void
    onClickHistory: (item: DataItemUnion) => void
    onClickShowItemVersion: (version: DataItemVersion) => void
    onClickReactivateItemVersion: (version: DataItemVersion) => void

    // error handling
    errors: FormError[]
    setErrors: (errors: FormError[]) => void
}

const mutationOptions: Omit<MutationHookOptions, 'variables'> = {
    refetchQueries: [DATA_TYPE_GET_WITH_ITEMS],
}

export const useDataItemPage = (): UseDataItemPageReturn => {
    // global state
    const website = useStoreState((state) => state.model.selectedWebsite)?.id ?? ''
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const assets = useStoreState((state) => state.model.assets)
    const pages = useStoreState((state) => state.model.pageList)

    // local state
    const [selectedType, setSelectedType] = useState<DataType>()
    const [attributes, setAttributes] = useState<AttributeUnion[]>([])
    const [selectedItem, setSelectedItem] = useState<DataItemUnion>()
    const [selectedVersion, setSelectedVersion] = useState<DataItemVersionUnion>()
    const [items, setItems] = useState<DataItemUnion[]>([])
    const [historyMap, setHistoryMap] = useState<Record<string, DataItemVersion[] | undefined>>({})
    const [showHistory, setShowHistory] = useState(false)

    // queries
    const { data } = useQuery<Query, QueryDataTypeGetWithItemsArgs>(DATA_TYPE_GET_WITH_ITEMS, {
        variables: { category: DataTypeCategory.DATA_MODULE, website },
    })
    const dataTypesWithItems = useMemo(() => data?.dataTypeGetWithItems ?? [], [data?.dataTypeGetWithItems])
    const dataTypes = useMemo(() => dataTypesWithItems.map((item) => item.dataType), [dataTypesWithItems])

    const { data: dataSubTypesGet } = useQuery<Query, QueryDataTypeGetArgs>(DATA_TYPE_GET, {
        variables: {
            category: DataTypeCategory.SUB_TYPE,
            website,
        },
    })
    const subTypes = useMemo(() => dataSubTypesGet?.dataTypeGet ?? [], [dataSubTypesGet?.dataTypeGet])

    const [getHistory] = useLazyQuery<Query, QueryDataItemGetHistoryArgs>(DATA_ITEM_GET_HISTORY)

    // error handling
    const { errors, setErrors } = useDataErrors(assets, selectedItem, attributes, subTypes)

    // default select first
    useEffect(() => {
        if (!selectedType) setSelectedType(dataTypes.at(0))
        else setSelectedType(dataTypes.find((item) => item.id === selectedType.id))
    }, [dataTypes, selectedType])

    // build attributes from linked list
    useEffect(() => {
        if (selectedType?.attributes) setAttributes(getOrderedAttributeUnion(selectedType.attributes))
    }, [selectedType?.attributes])

    // update selected item if the item list changes
    useEffect(() => {
        if (selectedItem?.id !== '') setSelectedItem(items.find((item) => item.id === selectedItem?.id))
    }, [items, selectedItem?.id])

    // reset history view open if you click on another item
    useEffect(() => setShowHistory(false), [selectedItem?.id])

    // reset on type change
    useEffect(() => setSelectedItem(undefined), [selectedType?.id])

    // build items from attributes and dataItems
    useEffect(() => {
        const dataItems = dataTypesWithItems.find((item) => item.dataType.id === selectedType?.id)?.dataItems ?? []
        setItems(getDataItemUnionList(attributes, dataItems))
    }, [dataTypesWithItems, attributes, selectedType?.id])

    // set selected version whenever selectedItem changes
    useEffect(() => {
        if (selectedItem) {
            const snapshotAttributesCollection = attributes
                .map((item) => (item.__typename === 'AttributeCollection' ? item.dataType : undefined)) // get ids
                .map((id) => subTypes.find((item) => item.id === id)) // get sub corresponding subtypes
                .map(
                    (subType) =>
                        subType && {
                            id: subType.id,
                            attributes: getOrderedAttributeUnion(subType?.attributes),
                        },
                )
                .filter((item) => item) as AttributeUnionWithID[] // filter undefineds

            const version: DataItemVersionUnion = {
                id: '',
                published: selectedItem?.published,
                snapshotAttributes: attributes,
                snapshotAttributesCollection,
                snapshotValues: selectedItem.values,
            }
            setSelectedVersion(version)
        }
    }, [selectedItem, attributes, subTypes])

    // mutations
    const [createDataItem] = useMutation<Mutation, MutationDataItemCreateArgs>(DATA_ITEM_CREATE, {
        ...mutationOptions,
        onCompleted: (data) => selectedItem && setSelectedItem({ ...selectedItem, id: data.dataItemCreate }),
    })
    const [updateDataItem] = useMutation<Mutation, MutationDataItemUpdateArgs>(DATA_ITEM_UPDATE, mutationOptions)
    const [deleteDataItem] = useMutation<Mutation, MutationDataItemDeleteArgs>(DATA_ITEM_DELETE, {
        ...mutationOptions,
        onCompleted: (data) => {
            if (selectedItem?.id === data.dataItemDelete) setSelectedItem(undefined)
        },
    })
    const [publishDataItem] = useMutation<Mutation, MutationDataItemPublishArgs>(DATA_ITEM_PUBLISH, mutationOptions)
    const [setEnabledDataItem] = useMutation<Mutation, MutationDataItemSetEnabledArgs>(
        DATA_ITEM_SET_ENABLED,
        mutationOptions,
    )

    // functions
    // TODO: handle draft confirm discard
    const onClickType = (item: DataType) => {
        setErrors([])
        setSelectedType(item)
    }

    const onClickItem = (item: DataItemUnion) => setSelectedItem(item)
    const onClickCreateItem = () => setSelectedItem(createDefaultDataItem(attributes))
    const onClickDuplicateItem = (item: DataItemUnion) =>
        setSelectedItem({ ...item, id: '', enabled: true, published: false })

    const onClickSaveItem = () => {
        if (selectedType && selectedItem && selectedVersion) {
            const valueItem: DataItemUnion = { ...selectedItem, values: selectedVersion.snapshotValues }
            const item = getDiscriminatedItemFromDataItemUnion(valueItem)
            const input = getDataItemInputFromType(item)
            if (input.id === '') {
                input.id = v4()
                createDataItem({ variables: { dataTypeID: selectedType.id, input } })
            } else updateDataItem({ variables: { input } })
            setSelectedItem(undefined)
        }
    }
    const onClickCancelItem = () => setSelectedItem(undefined)
    const onClickDeleteItem = (item: DataItemUnion) => deleteDataItem({ variables: { id: item.id } })
    const onClickPublish = (item: DataItemUnion) => {
        publishDataItem({
            variables: {
                items: [item.id],
            },
        })
    }
    const onClickPublishAll = () => {
        publishDataItem({
            variables: {
                items: items.filter((item) => !item.published).map((item) => item.id),
            },
        })
    }
    const onClickToggleEnabled = (item: DataItemUnion) => {
        setEnabledDataItem({
            variables: {
                items: [item.id],
                enabled: !item.enabled,
            },
        })
    }

    const onClickShowItemVersion = (version: DataItemVersion) => {
        const snapshotAttributes = getOrderedAttributeUnion(version.snapshotAttributes)

        const versionUnion: DataItemVersionUnion = {
            id: version.id,
            published: version.published,
            snapshotAttributes,
            snapshotAttributesCollection: version.snapshotAttributesCollection.map((item) => ({
                id: item.dataType,
                attributes: getOrderedAttributeUnion(item.attributes),
            })),
            snapshotValues: getOrderedAttributeValueUnion(snapshotAttributes, version.snapshotValues),
        }
        setSelectedVersion(versionUnion)
    }

    const onClickReactivateItemVersion = (version: DataItemVersion) => {
        if (!selectedItem) return
        const latestVersion = historyMap[selectedItem?.id]?.at(0)
        if (!latestVersion) return

        // go back to normal edit view
        setShowHistory(false)

        const rollback: DataItemVersionUnion = {
            id: v4(),
            published: false,
            snapshotAttributes: getOrderedAttributeUnion(latestVersion.snapshotAttributes),
            snapshotAttributesCollection: latestVersion.snapshotAttributesCollection.map((item) => ({
                id: item.dataType,
                attributes: getOrderedAttributeUnion(item.attributes),
            })),
            snapshotValues: [],
        }

        const previousAttributes = getOrderedAttributeUnion(version.snapshotAttributes)
        const previousValues = getOrderedAttributeValueUnion(previousAttributes, version.snapshotValues)

        // create rolled back values from the initial values + everything that can be restored
        rollback.snapshotValues = createInitialAttributeValues(rollback.snapshotAttributes).map((item) => {
            const previousValue = previousValues.find((value) => value.attributeID === item.attributeID)

            // handle nested values same way basically
            if (previousValue?.__typename === 'AttributeCollectionValue') {
                const attribute = rollback.snapshotAttributes.find(
                    (attribute) => attribute.id === previousValue.attributeID,
                )
                if (attribute?.__typename !== 'AttributeCollection') return item

                const subType = latestVersion.snapshotAttributesCollection.find(
                    (subType) => subType.dataType === attribute.dataType,
                )
                if (!subType) return item

                const attributesNested = getOrderedAttributeUnion(subType.attributes)

                const previousSubType = version.snapshotAttributesCollection.find(
                    (subType) => subType.dataType === attribute.dataType,
                )
                if (!previousSubType) return item

                const previousAttributesNested = getOrderedAttributeUnion(previousSubType.attributes)

                return {
                    __typename: 'AttributeCollectionValue',
                    attributeID: item.attributeID,
                    value: previousValue.value.map((valueItem) => {
                        const previousValuesNested = getOrderedAttributeValueUnion(previousAttributesNested, valueItem)
                        const valuesNested: AttributeValueUnion[] = createInitialAttributeValues(attributesNested).map(
                            (nestedItem) =>
                                previousValuesNested.find(
                                    (nestedValue) => nestedValue.attributeID === nestedItem.attributeID,
                                ) ?? nestedItem,
                        )

                        return getCollectionValueItemFromAttributeValues(valueItem.id, valuesNested)
                    }),
                }
            }

            // if a proper value was found, return that, otherwise return default value
            return previousValue ?? item
        })

        // then let the user fix potential issues himself
        setSelectedVersion(rollback)
    }

    const onClickHistory = (item: DataItemUnion) => {
        if (showHistory) {
            setShowHistory(false)
            const version = historyMap[item.id]?.at(0)
            if (version) onClickShowItemVersion({ ...version })
            else setSelectedVersion(undefined)
        } else {
            setShowHistory(true)

            getHistory({
                variables: {
                    dataItemID: item.id,
                },
                onCompleted: (data) => {
                    const history = { ...historyMap }
                    history[item.id] = data.dataItemGetHistory
                    setHistoryMap(history)

                    const version = data.dataItemGetHistory.at(0)
                    if (version) onClickShowItemVersion(version)
                },
            })
        }
    }

    const onUpdateValue = (value: AttributeValueUnion) => {
        if (selectedVersion)
            setSelectedVersion({
                ...selectedVersion,
                snapshotValues: selectedVersion.snapshotValues.map((item) =>
                    item.attributeID === value.attributeID ? value : item,
                ),
            })
    }

    return {
        unpublishedItems,
        assets,
        pages,
        items,
        dataTypes,
        attributes,
        selectedVersion,
        selectedItem,
        selectedType,
        showHistory,
        historyMap,
        onUpdateValue,
        setSelectedItem,
        setSelectedType,
        onClickCancelItem,
        onClickCreateItem,
        onClickDuplicateItem,
        onClickDeleteItem,
        onClickItem,
        onClickSaveItem,
        onClickType,
        onClickPublish,
        onClickPublishAll,
        onClickToggleEnabled,
        onClickHistory,
        onClickReactivateItemVersion,
        onClickShowItemVersion,
        errors,
        setErrors,
    }
}
