import { TextField } from '@mui/material'
import { AttributeTextValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeTextValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, onUpdateValue, errors, index, disabled } = props

    if (attributeValue.__typename !== 'AttributeTextValue') return null
    if (!attribute || attribute.__typename !== 'AttributeText') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeTextValue>) =>
        onUpdateValue({
            ...attributeValue,
            ...values,
        })

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error = errors.find((error) => error.type === `innerTextEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`)

    return (
        <TextField
            label={attribute.common.name}
            value={value}
            onChange={(event) => override({ value: event.target.value })}
            multiline={Boolean(attribute.multiLine)}
            rows={attribute.multiLine ? 4 : 1}
            error={!!error}
            helperText={error?.message ?? ''}
            disabled={disabled}
        />
    )
}
