import { Autocomplete, Box, Button, Chip, Grid, Stack, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import { AssetFolder } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface AssetFolderPanelProps {
    folder: AssetFolder
    tagList: string[]
    onCancel: () => void
    onSubmit: (title: string, tags: string[]) => void
}

interface AssetFolderPanelInputs {
    Title: string
    Tags: string[]
}

const AssetFolderPanel = (props: AssetFolderPanelProps): JSX.Element => {
    const { folder, onCancel, onSubmit, tagList } = props

    const { t } = useTranslation()

    const { handleSubmit, control } = useForm<AssetFolderPanelInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Title: folder.name,
            Tags: folder.tags ?? [],
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: AssetFolderPanelInputs) => {
            onSubmit(data.Title, data.Tags)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{folder.name}</Typography>
            </Grid>
            <Grid container justifyContent={'space-between'}>
                {/* TODO: Get the size of the directory. We have to get all files and sum up the size.  */}
                {/* <Grid item xs={12}>
                    <Typography variant="body1">
                        {t('drawerPanels.assetFolderPanel.size')} {filesize(1234)}
                    </Typography>
                </Grid> */}
                <Grid item xs={12} mb={2}>
                    <Typography variant="body1">
                        {t('drawerPanels.assetFolderPanel.created')}{' '}
                        {format(new Date(folder.createdAt), 'dd.MM.yyyy HH:mm')}
                    </Typography>
                </Grid>
                <Grid container mb={2}>
                    <Controller
                        name="Title"
                        control={control}
                        rules={{
                            required: t('drawerPanels.assetFolderPanel.nameRequired'),
                            maxLength: { value: 150, message: t('drawerPanels.assetFolderPanel.nameMaxLength') },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label={t('drawerPanels.assetFolderPanel.name')}
                                inputProps={{ maxLength: 150 }}
                                variant={'filled'}
                                fullWidth={true}
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message ?? ''}
                            />
                        )}
                    />
                </Grid>
                <Grid container mb={2}>
                    <Controller
                        name="Tags"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                multiple
                                fullWidth
                                id="Tags"
                                defaultValue={folder.tags ?? []}
                                options={tagList}
                                freeSolo
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                onChange={(event, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        label={t('drawerPanels.assetFolderPanel.tags')}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOnSave}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}

export default AssetFolderPanel
