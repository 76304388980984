import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import AuthWrapper from 'components/AuthWrapper/AuthWrapper'
import ErrorDialog from 'components/ErrorDialog'
import GeneralSnackbar, { SnackbarType } from 'components/GeneralSnackbar'
import { ErrorConfig } from 'graphql/types'
import AssetManagerPage from 'pages/AssetManagerPage/AssetManagerPage'
import BlockListPage from 'pages/BlockListPage/BlockListPage'
import { DataItemPage } from 'pages/DataItemPage/DataItemPage'
import NotLoggedIn from 'pages/NotLoggedIn/NotLoggedIn'
import PageEditPage from 'pages/PageEditPage/PageEditPage'
import PageTreePage from 'pages/PageTreePage/PageTreePage'
import { PublishPage } from 'pages/PublishPage/PublishPage'
import { SettingsPage } from 'pages/SettingsPage/SettingsPage'
import WebsiteCreatePage from 'pages/WebsiteCreatePage/WebsiteCreatePage'
import { useState } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { getGraphQLServerURI } from 'utils/getEnvConfig'
import 'utils/i18n'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { ModifiedTheme } from 'utils/theme'
import { useApolloClient } from 'utils/useApolloClient'

function App(): JSX.Element {
    const serverError = useStoreState((state) => state.model.serverError)
    const generalSnackbarConfig = useStoreState((state) => state.model.generalSnackbarConfig)
    const configureSnackbar = useStoreActions((actions) => actions.model.configureSnackbar)
    const configureServerError = useStoreActions((actions) => actions.model.configureServerError)

    const [error, setError] = useState<ErrorConfig>()
    const apolloClient = useApolloClient(getGraphQLServerURI(), setError)

    if (!apolloClient) return <></>

    const combinedError = serverError || error

    return (
        <AuthWrapper>
            <ApolloProvider client={apolloClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <HashRouter>
                        <ThemeProvider theme={ModifiedTheme}>
                            {combinedError && (
                                <ErrorDialog
                                    errorConfig={combinedError}
                                    onClick={() => {
                                        configureServerError(undefined)
                                        window.location.reload()
                                    }}
                                />
                            )}
                            <GeneralSnackbar
                                message={generalSnackbarConfig.message}
                                isOpen={generalSnackbarConfig.isOpen}
                                type={generalSnackbarConfig.type}
                                onClose={() => {
                                    configureSnackbar({ isOpen: false, message: '', type: SnackbarType.INFO })
                                }}
                            />
                            {!combinedError && (
                                <Routes>
                                    <Route path={NavigationRoutes.PAGE_TREE} element={<PageTreePage />} />
                                    <Route path={NavigationRoutes.BLOCK_LIST} element={<BlockListPage />} />
                                    <Route path={NavigationRoutes.PAGE_EDIT} element={<PageEditPage />} />
                                    <Route path={NavigationRoutes.ASSETS} element={<AssetManagerPage />} />
                                    <Route path={NavigationRoutes.NOT_LOGGED_IN} element={<NotLoggedIn />} />
                                    <Route path={NavigationRoutes.DATA_ITEMS} element={<DataItemPage />} />
                                    <Route path={NavigationRoutes.PUBLISH} element={<PublishPage />} />
                                    <Route path={NavigationRoutes.SETTINGS} element={<SettingsPage />} />
                                    <Route path={NavigationRoutes.WEBSITE_ADD} element={<WebsiteCreatePage />} />

                                    {/* catch all redirect */}
                                    <Route path={'*'} element={<Navigate to={NavigationRoutes.PAGE_TREE} replace />} />
                                </Routes>
                            )}
                        </ThemeProvider>
                    </HashRouter>
                </LocalizationProvider>
            </ApolloProvider>
        </AuthWrapper>
    )
}

export default App
