import { SubdirectoryArrowRight } from '@mui/icons-material'
import { Autocomplete, Box, TextField, TextFieldProps, Tooltip, Typography } from '@mui/material'
import { Asset, AssetFolder, AssetType, Frequency, Page, PageRank, RobotsOption, State } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store/hooks'
import { resolveFolderPath, resolvePageURL } from 'utils/linkResolver'
import {
    AutocompleteNestedItem,
    AutocompleteNestedProps,
    isRootParentItem,
    useAutocompleteNested,
} from './UseAutocompleteNested'

export const AutocompleteNested = <T extends unknown>(props: AutocompleteNestedProps<T>) => {
    const hookReturn = useAutocompleteNested(props)

    const { t } = useTranslation()

    return (
        <Tooltip title={t('components.autocompleteNested.tooltip')} placement="right">
            <Autocomplete {...hookReturn} />
        </Tooltip>
    )
}

type AutocompleteNestedWrapperProps<T, DefaultValueType = T> = Omit<
    AutocompleteNestedProps<T>,
    'options' | 'renderOption' | 'renderInput' | 'defaultValue'
> & { inputProps?: TextFieldProps; defaultValue?: DefaultValueType }

export const AutocompleteNestedPage = (props: AutocompleteNestedWrapperProps<Page>) => {
    const { t } = useTranslation()

    const pages = useStoreState((state) => state.model.pageList)

    const pageToOption = (page: Page) => {
        const pageSettings = page.currentVersion.pageSettings
        const resolved = resolvePageURL(page.id, pages, true)

        return {
            item: page,
            group: resolved,
            key: pageSettings.urlTitle,
            value: pageSettings.htmlTitle,
        }
    }

    const options: AutocompleteNestedItem<Page>[] = pages.map(pageToOption)
    // sadly those have to have default objects, not empty values
    // otherwise we will get into trouble with all the undefined checks everywhere
    const rootParentOption: AutocompleteNestedItem<Page> = {
        item: {
            id: '',
            createdAt: 0,
            currentVersion: {
                createdAt: 0,
                contents: '',
                id: '',
                pageID: '',
                pageSettings: {
                    changeFrequency: Frequency.ALWAYS,
                    htmlTitle: '',
                    includeInSitemapXML: true,
                    language: '',
                    metaDescription: '',
                    metaKeywords: '',
                    pageRank: PageRank.EIGHT,
                    robotsOption: RobotsOption.BOTH,
                    urlTitle: '',
                },
                state: State.DELETED,
                updatedAt: 0,
                updatedBy: {
                    id: '',
                    name: '',
                    realmIDs: [],
                },
            },
            isTemplate: false,
            deactivated: true,
            sortIndex: 0,
            updatedAt: 0,
        },
        group: '',
        key: '',
        value: t('components.autocompleteNested.back'),
    }

    return (
        <AutocompleteNested<Page>
            options={[rootParentOption, ...options]}
            renderInput={(params) => <TextField {...params} {...props.inputProps} />}
            renderOption={(props, option) => (
                <Box component="li" display={'flex'} flexDirection={'row'} {...props}>
                    {/* its not currently possible to show context aware icons */}
                    <SubdirectoryArrowRight />

                    <Box display={'flex'} flexDirection={'column'} marginLeft={1}>
                        <Typography alignSelf={'flex-start'} noWrap>
                            {option.value}
                        </Typography>
                        {/* https://en.wikipedia.org/wiki/Help:Link_color */}
                        <Typography variant="body2" alignSelf={'flex-start'} color="#3366CC" noWrap>
                            {option.key}
                        </Typography>
                    </Box>
                </Box>
            )}
            // override with any passed props
            {...props}
            // generate default value item
            defaultValue={props.defaultValue && pageToOption(props.defaultValue)}
            disableCloseOnSelect
        />
    )
}

export const AutocompleteNestedAsset = (props: AutocompleteNestedWrapperProps<Asset | AssetFolder, Asset>) => {
    const assets = useStoreState((state) => state.model.assets)
    const folders = useStoreState((state) => state.model.folders)

    const { t } = useTranslation()

    const assetToOption = (asset: Asset) => {
        const resolved = resolveFolderPath(asset.folder ?? undefined, folders)

        // set typename for later discrimination
        asset.__typename = 'Asset'

        return {
            item: asset,
            group: resolved,
            key: asset.url.split('/').at(-1) ?? '',
            value: asset.name,
        }
    }

    const folderToOption = (folder: AssetFolder) => {
        const resolved = resolveFolderPath(folder.parentFolder ?? undefined, folders)

        // set typename for later discrimination
        folder.__typename = 'AssetFolder'

        return {
            item: folder,
            group: resolved,
            key: folder.name,
            value: '', // causes folders to always sort first and theres nothing sensible here anyhow
        }
    }

    // each option contains either a folder or an asset
    // with the item being the thumbnail url for renderOption
    const assetOptions: AutocompleteNestedItem<Asset | AssetFolder>[] = assets.map(assetToOption)
    const folderOptions: AutocompleteNestedItem<Asset | AssetFolder>[] = folders.map(folderToOption)
    const rootParentOption: AutocompleteNestedItem<AssetFolder> = {
        item: {
            id: '',
            createdAt: 0,
            name: '',
            websiteID: '',
        },
        group: '',
        key: '',
        value: t('components.autocompleteNested.back'),
    }

    return (
        <AutocompleteNested<Asset | AssetFolder>
            options={[rootParentOption, ...assetOptions, ...folderOptions]}
            getOptionLabel={(option) => (option.value ? option.value : option.key)}
            renderInput={(params) => <TextField {...params} {...props.inputProps} />}
            renderOption={(props, option) => {
                let url = ''
                if (isRootParentItem(option)) {
                    url = '/img/folder.png'
                } else if (option.item.__typename === 'Asset') {
                    const asset = option.item
                    if (asset.type === AssetType.IMAGE) url = asset.thumbnailUrl ?? asset.url
                    else if (asset.type === AssetType.PDF) url = '/img/pdf.png'
                    else '/img/file.png'
                } else if (option.item.__typename === 'AssetFolder') url = '/img/folder.png'

                return (
                    <Box
                        component="li"
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        sx={{
                            '&:hover > img': {
                                // (64+2*8)/64, couldnt figure out to make image expand beyond box
                                transform: 'scale(1.25)',
                            },
                        }}
                        {...props}
                    >
                        <img
                            src={url}
                            alt={t('components.autocompleteNested.alt')}
                            width={64}
                            height={64}
                            style={{
                                marginRight: 8,
                                objectFit: 'contain',
                                opacity: isRootParentItem(option) ? 0.4 : 1,
                            }}
                        />

                        <Typography>
                            {option.value ? option.value : t('components.autocompleteNested.folder')}
                        </Typography>
                    </Box>
                )
            }}
            // override with any passed props
            {...props}
            // generate default value item
            defaultValue={props.defaultValue && assetToOption(props.defaultValue)}
            disableCloseOnSelect
            isFinalItem={(item) => item?.__typename === 'Asset'}
        />
    )
}
