import { Box, CircularProgress } from '@mui/material'
import { default as CMSHeader } from 'components/CMSHeader'
import DrawerGrid from 'components/DrawerGrid'
import { DrawerWidth } from 'components/DrawerGrid/DrawerGrid'
import ExportImportDialog from 'components/ExportImportDialog/ExportImportDialog'
import PagePreview from 'components/PagePreview'
import BlockCreatePanel from 'drawerPanels/BlockCreatePanel'
import BlockDetailsPanel from 'drawerPanels/BlockDetailsPanel'
import BlockSettingsEditPanel from 'drawerPanels/BlockSettingsEditPanel'
import BlockTreePanel from 'drawerPanels/BlockTreePanel'
import { BlockVersion, ImportType } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'store/hooks'
import { NavigationRoutes } from 'utils/navigationRoutes'
import { getParsedPageElements } from 'utils/parseElementContent'
import { EditingDataType, PageElement } from 'utils/types'

export enum BlockListViewMode {
    BLOCKLIST = 'BLOCKLIST',
    ADD = 'ADD',
    EDIT = 'EDIT',
}

const BlockListPage = (): JSX.Element => {
    //data
    const elements: any = {}

    //hooks and data from store
    const navigate = useNavigate()

    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    const currentlySelectedBlock = useStoreState((state) => state.model.currentlySelectedBlock)
    const unpublishedItems = useStoreState((state) => state.model.unpublishedItems)
    const blockList = useStoreState((state) => state.model.blockList)
    const blockData = useStoreState((state) => state.model.blockData)
    const loggedInUser = useStoreState((state) => state.model.loggedInUser)
    const assetsSimplified = useStoreState((state) => state.model.assetsSimplified)
    const savingCurrentVersionDisabled = useStoreState((state) => state.model.savingCurrentVersionDisabled)
    const websiteList = useStoreState((state) => state.model.websiteList)
    const historyMap = useStoreState((state) => state.model.blockHistoryMap)

    const changeDialogState = useStoreActions((actions) => actions.importModel.changeDialogState)
    const startEditingPage = useStoreActions((actions) => actions.model.startEditingPage)
    const loadBlock = useStoreActions((actions) => actions.model.loadBlock)
    const deleteBlock = useStoreActions((actions) => actions.model.deleteBlock)
    const duplicateBlock = useStoreActions((actions) => actions.model.duplicateBlock)
    const createBlock = useStoreActions((actions) => actions.model.createBlock)
    const saveBlock = useStoreActions((actions) => actions.model.saveBlock)
    const setSelectedWebsite = useStoreActions((actions) => actions.model.setSelectedWebsite)
    const loadHistory = useStoreActions((actions) => actions.model.loadBlockHistory)

    const { t } = useTranslation()

    //internal state
    const [viewMode, setViewMode] = useState<BlockListViewMode>(BlockListViewMode.BLOCKLIST)
    const [pageElements, setPageElements] = useState<PageElement[]>([])
    const [selectedVersion, setSelectedVersion] = useState<BlockVersion>()

    // set version when we switch block
    useEffect(() => {
        setSelectedVersion(currentlySelectedBlock?.currentVersion)
    }, [currentlySelectedBlock?.id, currentlySelectedBlock?.currentVersion])

    // set page elements when the contents change
    useEffect(() => setPageElements(getParsedPageElements(selectedVersion?.contents)), [selectedVersion?.contents])

    // set page elements when we click on view for a previous version
    const showBlockVersion = (version: BlockVersion) => currentlySelectedBlock && setSelectedVersion(version)

    // add a new page version with the old contents
    const reactivateBlockVersion = (version: BlockVersion) => {
        if (currentlySelectedBlock) {
            saveBlock({
                blockID: currentlySelectedBlock.id,
                content: version.contents,
                identifier: version.id,
                name: version.name,
            })
            setSelectedVersion(version)
        }
    }

    //functions
    const selectBlock = (id: string) => {
        if (currentlySelectedBlock === undefined || (currentlySelectedBlock && currentlySelectedBlock.id !== id)) {
            loadBlock(id)
        }
    }

    const onDeleteBlock = (id: string) => {
        if (confirm(t('pages.blockListPage.confirmDeleteBlock'))) {
            deleteBlock(id)
        }
    }

    const onDuplicateBlock = (id: string) => {
        duplicateBlock(id)
    }

    const onSaveBlock = (name: string, identifier: string) => {
        if (currentlySelectedBlock)
            saveBlock({
                blockID: currentlySelectedBlock.id,
                name,
                content: currentlySelectedBlock.currentVersion.contents,
                identifier,
            })
        setViewMode(BlockListViewMode.BLOCKLIST)
    }

    const onEditBlock = () => {
        if (currentlySelectedBlock) {
            startEditingPage({
                currentlyEditingData: {
                    id: currentlySelectedBlock.id,
                    identifier: currentlySelectedBlock.identifier,
                    label: currentlySelectedBlock.currentVersion.name,
                    contents: currentlySelectedBlock.currentVersion.contents,
                    type: EditingDataType.BLOCK,
                },
                pageElementContents: getParsedPageElements(currentlySelectedBlock.currentVersion.contents),
            })
            navigate(NavigationRoutes.PAGE_EDIT)
        }
    }

    // ----------------------------------------------------------------------- Loading until webiste and cms data are loaded
    if (!selectedWebsite || !loggedInUser) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }

    elements['primary'] = (
        <BlockTreePanel
            blocks={blockList}
            onSelectBlock={selectBlock}
            onBlockCreate={() => setViewMode(BlockListViewMode.ADD)}
            selectedBlock={currentlySelectedBlock ? currentlySelectedBlock.id : ''}
        />
    )

    if (viewMode === BlockListViewMode.BLOCKLIST) {
        // TODO: the way I did this is a quick hack. I am not sure what the best way is - if a page has only 5 different
        // view modes then I guess putting them all into one file is ok. There is also something to watch out for, if
        // the DrawerGrid is unmounted and remounted (this happened when I was using routes to switch between the
        // different view modes by splitting this page into several pages) then the transitions do not work.
        // I guess we could have one view mode as a separate file and just do the switch here or actually leave it all in here but add properly typed accessors for elements[DrawerGrid.primaryPanel] (can we do some typescript typeof magic to get the fields of the drawerGridProps?)

        // ------------------------------------------------------------------------- page tree
        if (currentlySelectedBlock) {
            elements['secondary'] = (
                <BlockDetailsPanel
                    block={currentlySelectedBlock}
                    onEditBlock={onEditBlock}
                    onEditSettings={() => setViewMode(BlockListViewMode.EDIT)}
                    onDuplicate={onDuplicateBlock}
                    onDelete={onDeleteBlock}
                    showBlockVersion={showBlockVersion}
                    reactivateBlockVersion={reactivateBlockVersion}
                    loadHistory={loadHistory}
                    historyMap={historyMap}
                    selectedVersion={selectedVersion}
                    openExportDialog={() => changeDialogState({ openDialog: true, type: ImportType.BLOCK })}
                />
            )
        }
        // primaryDrawerWidth={DrawerWidth.SMALL}
        // secondaryDrawerWidth={DrawerWidth.MEDIUM}
        elements['collapsible'] = true
    }

    // ------------------------------------------------------------------------- edit view
    else if (viewMode === BlockListViewMode.EDIT) {
        elements['secondary'] = (
            <BlockSettingsEditPanel
                saveIsDisabled={savingCurrentVersionDisabled}
                label={currentlySelectedBlock?.currentVersion.name ?? ''}
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                identifier={currentlySelectedBlock?.identifier ?? ''}
                onSubmit={onSaveBlock}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    // ------------------------------------------------------------------------- add view
    else if (viewMode === BlockListViewMode.ADD) {
        elements['secondary'] = (
            <BlockCreatePanel
                onCancel={() => setViewMode(BlockListViewMode.BLOCKLIST)}
                onSubmit={(label: string) => {
                    createBlock({
                        name: label,
                        websiteID: selectedWebsite.id,
                    })
                    setViewMode(BlockListViewMode.BLOCKLIST) //TODO find a better way of closing the panel after creating
                }}
            />
        )

        elements['secondaryWidth'] = DrawerWidth.MEDIUM
        elements['collapsible'] = false
        elements['primaryDisabled'] = true
    }

    return (
        <>
            <CMSHeader
                unpublishedItems={unpublishedItems}
                selectedWebsite={selectedWebsite?.id ?? ''}
                websiteList={websiteList}
                onSelectWebsite={setSelectedWebsite}
            />
            <DrawerGrid
                {...elements}
                content={
                    <PagePreview
                        assets={assetsSimplified}
                        disabled={true}
                        blocks={blockData}
                        pageElements={pageElements}
                        activeBreakpoint={{
                            identifier: 'xl',
                            name: 'xl',
                            editorCssWidth: '100%',
                            fromWidthPixels: 1536,
                        }}
                    />
                }
            />
            <ExportImportDialog />
        </>
    )
}

export default BlockListPage
