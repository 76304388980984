export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
}

export type Asset = {
    __typename?: 'Asset'
    createdAt: Scalars['Int']['output']
    createdBy: Scalars['String']['output']
    folder?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    sizeBytes: Scalars['Int']['output']
    tags?: Maybe<Array<Scalars['String']['output']>>
    thumbnailUrl?: Maybe<Scalars['String']['output']>
    type: AssetType
    updatedAt: Scalars['Int']['output']
    updatedBy: Scalars['String']['output']
    url: Scalars['String']['output']
    websiteID: Scalars['String']['output']
}

export type AssetFolder = {
    __typename?: 'AssetFolder'
    createdAt: Scalars['Int']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    parentFolder?: Maybe<Scalars['String']['output']>
    tags?: Maybe<Array<Scalars['String']['output']>>
    websiteID: Scalars['ID']['output']
}

export type AssetResponse = {
    __typename?: 'AssetResponse'
    assets: Array<Asset>
    folders: Array<AssetFolder>
}

export enum AssetType {
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
    PDF = 'PDF',
}

export type AssetUpdateInput = {
    AssetID: Scalars['ID']['input']
    folderID?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
    tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type AssetUploadInput = {
    file: Scalars['String']['input']
    folderID: Scalars['String']['input']
    name: Scalars['String']['input']
    resizeImages: Scalars['Boolean']['input']
    type: AssetType
    websiteID: Scalars['String']['input']
}

export type AttributeAsset = {
    __typename?: 'AttributeAsset'
    common: AttributeCommon
    id: Scalars['ID']['output']
    required?: Maybe<Scalars['Boolean']['output']>
}

export type AttributeAssetInput = {
    common: AttributeCommonInput
    id: Scalars['ID']['input']
    required?: InputMaybe<Scalars['Boolean']['input']>
}

export type AttributeAssetValue = {
    __typename?: 'AttributeAssetValue'
    alt?: Maybe<Scalars['String']['output']>
    attributeID: Scalars['ID']['output']
    value: Scalars['ID']['output']
}

export type AttributeAssetValueInput = {
    alt?: InputMaybe<Scalars['String']['input']>
    attributeID: Scalars['ID']['input']
    value: Scalars['ID']['input']
}

export type AttributeBoolean = {
    __typename?: 'AttributeBoolean'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
}

export type AttributeBooleanInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
}

export type AttributeBooleanValue = {
    __typename?: 'AttributeBooleanValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['Boolean']['output']
}

export type AttributeBooleanValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['Boolean']['input']
}

export type AttributeCollection = {
    __typename?: 'AttributeCollection'
    common: AttributeCommon
    dataType: Scalars['ID']['output']
    id: Scalars['ID']['output']
}

export type AttributeCollectionInput = {
    common: AttributeCommonInput
    dataType: Scalars['ID']['input']
    id: Scalars['ID']['input']
}

export type AttributeCollectionValue = {
    __typename?: 'AttributeCollectionValue'
    attributeID: Scalars['ID']['output']
    value: Array<AttributeCollectionValueItem>
}

export type AttributeCollectionValueInput = {
    attributeID: Scalars['ID']['input']
    value: Array<AttributeCollectionValueItemInput>
}

export type AttributeCollectionValueItem = {
    __typename?: 'AttributeCollectionValueItem'
    attributeAssetValues: Array<AttributeAssetValue>
    attributeBooleanValues: Array<AttributeBooleanValue>
    attributeDateValues: Array<AttributeDateValue>
    attributeLinkValues: Array<AttributeLinkValue>
    attributeNumberValues: Array<AttributeNumberValue>
    attributeSelectionValues: Array<AttributeSelectionValue>
    attributeTextValues: Array<AttributeTextValue>
    id: Scalars['ID']['output']
}

export type AttributeCollectionValueItemInput = {
    attributeAssetValues: Array<AttributeAssetValueInput>
    attributeBooleanValues: Array<AttributeBooleanValueInput>
    attributeDateValues: Array<AttributeDateValueInput>
    attributeLinkValues: Array<AttributeLinkValueInput>
    attributeNumberValues: Array<AttributeNumberValueInput>
    attributeSelectionValues: Array<AttributeSelectionValueInput>
    attributeTextValues: Array<AttributeTextValueInput>
    id: Scalars['ID']['input']
}

export type AttributeCommon = {
    __typename?: 'AttributeCommon'
    _next?: Maybe<Scalars['ID']['output']>
    description: Scalars['String']['output']
    identifier: Scalars['String']['output']
    name: Scalars['String']['output']
    visible: Scalars['Boolean']['output']
}

export type AttributeCommonInput = {
    description: Scalars['String']['input']
    identifier: Scalars['String']['input']
    name: Scalars['String']['input']
    visible: Scalars['Boolean']['input']
}

export type AttributeDate = {
    __typename?: 'AttributeDate'
    common: AttributeCommon
    id: Scalars['ID']['output']
}

export type AttributeDateInput = {
    common: AttributeCommonInput
    id: Scalars['ID']['input']
}

export type AttributeDateValue = {
    __typename?: 'AttributeDateValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['Int']['output']
}

export type AttributeDateValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['Int']['input']
}

export type AttributeLink = {
    __typename?: 'AttributeLink'
    allowed: AttributeLinkAllowedOptions
    common: AttributeCommon
    id: Scalars['ID']['output']
    required?: Maybe<Scalars['Boolean']['output']>
}

export enum AttributeLinkAllowedOptions {
    EXTERNAL = 'EXTERNAL',
    EXTERNAL_FILE = 'EXTERNAL_FILE',
    FILE = 'FILE',
    INTERNAL = 'INTERNAL',
    INTERNAL_EXTERNAL = 'INTERNAL_EXTERNAL',
    INTERNAL_EXTERNAL_FILE = 'INTERNAL_EXTERNAL_FILE',
    INTERNAL_FILE = 'INTERNAL_FILE',
}

export type AttributeLinkInput = {
    allowed: AttributeLinkAllowedOptions
    common: AttributeCommonInput
    id: Scalars['ID']['input']
    required?: InputMaybe<Scalars['Boolean']['input']>
}

export type AttributeLinkValue = {
    __typename?: 'AttributeLinkValue'
    attributeID: Scalars['ID']['output']
    newWindow: Scalars['Boolean']['output']
    value: Scalars['String']['output']
}

export type AttributeLinkValueInput = {
    attributeID: Scalars['ID']['input']
    newWindow: Scalars['Boolean']['input']
    value: Scalars['String']['input']
}

export type AttributeNumber = {
    __typename?: 'AttributeNumber'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['Float']['output']>
    id: Scalars['ID']['output']
    integer?: Maybe<Scalars['Boolean']['output']>
    max?: Maybe<Scalars['Float']['output']>
    min?: Maybe<Scalars['Float']['output']>
}

export type AttributeNumberInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['Float']['input']>
    id: Scalars['ID']['input']
    integer?: InputMaybe<Scalars['Boolean']['input']>
    max?: InputMaybe<Scalars['Float']['input']>
    min?: InputMaybe<Scalars['Float']['input']>
}

export type AttributeNumberValue = {
    __typename?: 'AttributeNumberValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['Float']['output']
}

export type AttributeNumberValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['Float']['input']
}

export type AttributeSelection = {
    __typename?: 'AttributeSelection'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    options: Array<AttributeSelectionOption>
    required?: Maybe<Scalars['Boolean']['output']>
}

export type AttributeSelectionInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    options: Array<AttributeSelectionOptionInput>
    required?: InputMaybe<Scalars['Boolean']['input']>
}

export type AttributeSelectionOption = {
    __typename?: 'AttributeSelectionOption'
    description: Scalars['String']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
}

export type AttributeSelectionOptionInput = {
    description: Scalars['String']['input']
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
}

export type AttributeSelectionValue = {
    __typename?: 'AttributeSelectionValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['ID']['output']
}

export type AttributeSelectionValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['ID']['input']
}

export type AttributeText = {
    __typename?: 'AttributeText'
    common: AttributeCommon
    defaultValue?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    multiLine?: Maybe<Scalars['Boolean']['output']>
    required?: Maybe<Scalars['Boolean']['output']>
}

export type AttributeTextInput = {
    common: AttributeCommonInput
    defaultValue?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    multiLine?: InputMaybe<Scalars['Boolean']['input']>
    required?: InputMaybe<Scalars['Boolean']['input']>
}

export type AttributeTextValue = {
    __typename?: 'AttributeTextValue'
    attributeID: Scalars['ID']['output']
    value: Scalars['String']['output']
}

export type AttributeTextValueInput = {
    attributeID: Scalars['ID']['input']
    value: Scalars['String']['input']
}

export type Block = {
    __typename?: 'Block'
    createdAt: Scalars['Int']['output']
    currentVersion: BlockVersion
    deletedAt?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    identifier: Scalars['String']['output']
    lastPublished?: Maybe<Scalars['Int']['output']>
}

export type BlockCreateInput = {
    name: Scalars['String']['input']
    websiteID: Scalars['ID']['input']
}

export type BlockSaveInput = {
    blockID: Scalars['ID']['input']
    content: Scalars['String']['input']
    identifier: Scalars['String']['input']
    name: Scalars['String']['input']
}

export type BlockVersion = {
    __typename?: 'BlockVersion'
    blockID: Scalars['ID']['output']
    contents: Scalars['String']['output']
    createdAt: Scalars['Int']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    state: State
    updatedAt: Scalars['Int']['output']
    updatedBy: User
}

export type CreatePageInput = {
    parentPageID?: InputMaybe<Scalars['ID']['input']>
    title: Scalars['String']['input']
    url: Scalars['String']['input']
    websiteID: Scalars['ID']['input']
}

export type DataItem = {
    __typename?: 'DataItem'
    created: DataItemChange
    enabled: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    published: Scalars['Boolean']['output']
    updated: DataItemChange
    values: DataItemValues
}

export type DataItemChange = {
    __typename?: 'DataItemChange'
    changeType: DataItemChangeType
    timestamp: Scalars['Int']['output']
    user: User
}

export enum DataItemChangeType {
    CREATE = 'CREATE',
    DISABLE = 'DISABLE',
    ENABLE = 'ENABLE',
    MIGRATE = 'MIGRATE',
    PUBLISH = 'PUBLISH',
    UPDATE = 'UPDATE',
}

export type DataItemInput = {
    attributeAssetValues: Array<AttributeAssetValueInput>
    attributeBooleanValues: Array<AttributeBooleanValueInput>
    attributeCollectionValues: Array<AttributeCollectionValueInput>
    attributeDateValues: Array<AttributeDateValueInput>
    attributeLinkValues: Array<AttributeLinkValueInput>
    attributeNumberValues: Array<AttributeNumberValueInput>
    attributeSelectionValues: Array<AttributeSelectionValueInput>
    attributeTextValues: Array<AttributeTextValueInput>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
    published?: InputMaybe<Scalars['Boolean']['input']>
}

export type DataItemValues = {
    __typename?: 'DataItemValues'
    attributeAssetValues: Array<AttributeAssetValue>
    attributeBooleanValues: Array<AttributeBooleanValue>
    attributeCollectionValues: Array<AttributeCollectionValue>
    attributeDateValues: Array<AttributeDateValue>
    attributeLinkValues: Array<AttributeLinkValue>
    attributeNumberValues: Array<AttributeNumberValue>
    attributeSelectionValues: Array<AttributeSelectionValue>
    attributeTextValues: Array<AttributeTextValue>
}

export type DataItemVersion = {
    __typename?: 'DataItemVersion'
    id: Scalars['ID']['output']
    published: Scalars['Boolean']['output']
    snapshotAttributes: DataTypeAttributes
    snapshotAttributesCollection: Array<DataTypeCollectionSnapshot>
    snapshotValues: DataItemValues
    updated: DataItemChange
}

export type DataType = {
    __typename?: 'DataType'
    attributes: DataTypeAttributes
    category: DataTypeCategory
    created: DataTypeChange
    description: Scalars['String']['output']
    id: Scalars['ID']['output']
    identifier: Scalars['String']['output']
    name: Scalars['String']['output']
    updated: DataTypeChange
}

export enum DataTypeAttributeType {
    ASSET = 'ASSET',
    BOOLEAN = 'BOOLEAN',
    COLLECTION = 'COLLECTION',
    DATE = 'DATE',
    LINK = 'LINK',
    NUMBER = 'NUMBER',
    SELECTION = 'SELECTION',
    TEXT = 'TEXT',
}

export type DataTypeAttributes = {
    __typename?: 'DataTypeAttributes'
    _head?: Maybe<Scalars['ID']['output']>
    attributeAssetList: Array<AttributeAsset>
    attributeBooleanList: Array<AttributeBoolean>
    attributeCollectionList: Array<AttributeCollection>
    attributeDateList: Array<AttributeDate>
    attributeLinkList: Array<AttributeLink>
    attributeNumberList: Array<AttributeNumber>
    attributeSelectionList: Array<AttributeSelection>
    attributeTextList: Array<AttributeText>
}

export enum DataTypeCategory {
    DATA_MODULE = 'DATA_MODULE',
    ELEMENT_DEFINITION = 'ELEMENT_DEFINITION',
    SUB_TYPE = 'SUB_TYPE',
}

export type DataTypeChange = {
    __typename?: 'DataTypeChange'
    changeType: DataTypeChangeType
    timestamp: Scalars['Int']['output']
    user: User
}

export enum DataTypeChangeType {
    CREATE = 'CREATE',
    CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE',
    CREATE_ITEM = 'CREATE_ITEM',
    DELETE_ITEM = 'DELETE_ITEM',
    REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE',
    REORDER_ATTRIBUTES = 'REORDER_ATTRIBUTES',
    UPDATE = 'UPDATE',
    UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE',
    UPDATE_ITEM = 'UPDATE_ITEM',
}

export type DataTypeCollectionSnapshot = {
    __typename?: 'DataTypeCollectionSnapshot'
    attributes: DataTypeAttributes
    dataType: Scalars['ID']['output']
}

export type DataTypeInput = {
    category: DataTypeCategory
    description: Scalars['String']['input']
    id: Scalars['ID']['input']
    identifier: Scalars['String']['input']
    name: Scalars['String']['input']
}

export type DataTypeWithItems = {
    __typename?: 'DataTypeWithItems'
    dataItems: Array<DataItem>
    dataType: DataType
}

export type DuplicatePageInput = {
    pageID: Scalars['ID']['input']
}

export type ElementDefinition = {
    __typename?: 'ElementDefinition'
    fields: Array<Maybe<Scalars['String']['output']>>
    identifier: Scalars['String']['output']
    name: Scalars['String']['output']
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    content: Scalars['String']['output']
    messages?: Maybe<Array<Scalars['String']['output']>>
    title: Scalars['String']['output']
}

export type FolderCreateInput = {
    name: Scalars['String']['input']
    parentFolderID?: InputMaybe<Scalars['ID']['input']>
    websiteID: Scalars['ID']['input']
}

export type FolderUpdateInput = {
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
    parentFolderID?: InputMaybe<Scalars['ID']['input']>
    tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum Frequency {
    ALWAYS = 'ALWAYS',
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
    NEVER = 'NEVER',
    WEEKLY = 'WEEKLY',
    YEARLY = 'YEARLY',
}

export type ImportDataContentInput = {
    changeFrequency: Scalars['String']['input']
    content: Scalars['String']['input']
    identifier: Scalars['String']['input']
    includeInSitemapXML: Scalars['Boolean']['input']
    language: Scalars['String']['input']
    metaDescription: Scalars['String']['input']
    metaKeywords: Scalars['String']['input']
    name: Scalars['String']['input']
    pageRank: Scalars['String']['input']
    robotsOption: Scalars['String']['input']
    urlTitle: Scalars['String']['input']
}

export type ImportDataInput = {
    content: ImportDataContentInput
    media: Array<ImportFileDataInput>
    type: ImportType
    websiteID: Scalars['ID']['input']
}

export type ImportFileDataInput = {
    folderPath: Scalars['String']['input']
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
    type: Scalars['String']['input']
    url: Scalars['String']['input']
}

export type ImportResponse = {
    __typename?: 'ImportResponse'
    block?: Maybe<Block>
    message: Scalars['String']['output']
    page?: Maybe<Page>
    success: Scalars['Boolean']['output']
}

export enum ImportType {
    BLOCK = 'BLOCK',
    PAGE = 'PAGE',
}

export type LoggedInUser = {
    __typename?: 'LoggedInUser'
    defaultWebsiteID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    realmIDs: Array<Scalars['String']['output']>
}

export enum MultiEditAllowedTypes {
    ALL = 'ALL',
    MEDIA_LINK = 'MEDIA_LINK',
    TEXT = 'TEXT',
}

export type MultiEditExcelResponse = {
    __typename?: 'MultiEditExcelResponse'
    excel: Scalars['String']['output']
    message: Scalars['String']['output']
    success: Scalars['Boolean']['output']
}

export type MultiResponse = {
    __typename?: 'MultiResponse'
    message: Scalars['String']['output']
    pages: Array<Page>
    success: Scalars['Boolean']['output']
}

export type Mutation = {
    __typename?: 'Mutation'
    assetDelete?: Maybe<Response>
    assetUpdate: Asset
    assetUpload: Asset
    blockCreate: Block
    blockDelete?: Maybe<Response>
    blockDuplicate: Block
    blockSave: Block
    createPage: Page
    createPageFromTemplate: MultiResponse
    dataItemCreate: Scalars['ID']['output']
    dataItemDelete: Scalars['ID']['output']
    dataItemPublish: Scalars['Boolean']['output']
    dataItemSetEnabled: Scalars['Boolean']['output']
    dataItemUpdate: Scalars['ID']['output']
    dataTypeCreate: Scalars['ID']['output']
    dataTypeCreateAttributeAsset: Scalars['ID']['output']
    dataTypeCreateAttributeBoolean: Scalars['ID']['output']
    dataTypeCreateAttributeCollection: Scalars['ID']['output']
    dataTypeCreateAttributeDate: Scalars['ID']['output']
    dataTypeCreateAttributeLink: Scalars['ID']['output']
    dataTypeCreateAttributeNumber: Scalars['ID']['output']
    dataTypeCreateAttributeSelection: Scalars['ID']['output']
    dataTypeCreateAttributeText: Scalars['ID']['output']
    dataTypeDelete: Scalars['ID']['output']
    dataTypeRemoveAttribute: Scalars['ID']['output']
    dataTypeReorderAttributes: Scalars['ID']['output']
    dataTypeUpdate: Scalars['ID']['output']
    dataTypeUpdateAttributeAsset: Scalars['ID']['output']
    dataTypeUpdateAttributeBoolean: Scalars['ID']['output']
    dataTypeUpdateAttributeCollection: Scalars['ID']['output']
    dataTypeUpdateAttributeDate: Scalars['ID']['output']
    dataTypeUpdateAttributeLink: Scalars['ID']['output']
    dataTypeUpdateAttributeNumber: Scalars['ID']['output']
    dataTypeUpdateAttributeSelection: Scalars['ID']['output']
    dataTypeUpdateAttributeText: Scalars['ID']['output']
    deactivatePage?: Maybe<Response>
    deletePage?: Maybe<Response>
    duplicatePage: Page
    folderCreate: AssetFolder
    folderDelete?: Maybe<Response>
    folderUpdate: AssetFolder
    importPage: ImportResponse
    multiEditPagesFromExcel?: Maybe<MultiResponse>
    pageTreeReorder?: Maybe<Response>
    publishPages?: Maybe<Response>
    realmCreate: Realm
    realmUpdate: Realm
    savePage: SavePageResponse
    userAssignRealm: LoggedInUser
    userCreate?: Maybe<LoggedInUser>
    userUnassignRealm: LoggedInUser
    verifyMultiEditExcel?: Maybe<VerifyMultiEditExcelResponse>
    verifyTemplateExcel?: Maybe<VerifyTemplateExcelResponse>
    websiteAssignRealm: Website
    websiteCreate: Website
    websiteSave: Website
}

export type MutationAssetDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationAssetUpdateArgs = {
    input: AssetUpdateInput
}

export type MutationAssetUploadArgs = {
    input: AssetUploadInput
}

export type MutationBlockCreateArgs = {
    input: BlockCreateInput
}

export type MutationBlockDeleteArgs = {
    ids: Array<Scalars['String']['input']>
}

export type MutationBlockDuplicateArgs = {
    blockID: Scalars['ID']['input']
}

export type MutationBlockSaveArgs = {
    input: BlockSaveInput
}

export type MutationCreatePageArgs = {
    input: CreatePageInput
}

export type MutationCreatePageFromTemplateArgs = {
    file: Scalars['String']['input']
    info: Array<TemplateExcelLngUrl>
    websiteID: Scalars['ID']['input']
}

export type MutationDataItemCreateArgs = {
    dataTypeID: Scalars['ID']['input']
    input: DataItemInput
}

export type MutationDataItemDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationDataItemPublishArgs = {
    items: Array<Scalars['ID']['input']>
}

export type MutationDataItemSetEnabledArgs = {
    enabled: Scalars['Boolean']['input']
    items: Array<Scalars['ID']['input']>
}

export type MutationDataItemUpdateArgs = {
    input: DataItemInput
}

export type MutationDataTypeCreateArgs = {
    input: DataTypeInput
    website: Scalars['ID']['input']
}

export type MutationDataTypeCreateAttributeAssetArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeAssetInput
}

export type MutationDataTypeCreateAttributeBooleanArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeBooleanInput
}

export type MutationDataTypeCreateAttributeCollectionArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeCollectionInput
}

export type MutationDataTypeCreateAttributeDateArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeDateInput
}

export type MutationDataTypeCreateAttributeLinkArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeLinkInput
}

export type MutationDataTypeCreateAttributeNumberArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeNumberInput
}

export type MutationDataTypeCreateAttributeSelectionArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeSelectionInput
}

export type MutationDataTypeCreateAttributeTextArgs = {
    dataTypeID: Scalars['ID']['input']
    input: AttributeTextInput
}

export type MutationDataTypeDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationDataTypeRemoveAttributeArgs = {
    id: Scalars['ID']['input']
}

export type MutationDataTypeReorderAttributesArgs = {
    order: Array<Scalars['ID']['input']>
}

export type MutationDataTypeUpdateArgs = {
    input: DataTypeInput
}

export type MutationDataTypeUpdateAttributeAssetArgs = {
    input: AttributeAssetInput
}

export type MutationDataTypeUpdateAttributeBooleanArgs = {
    input: AttributeBooleanInput
}

export type MutationDataTypeUpdateAttributeCollectionArgs = {
    input: AttributeCollectionInput
}

export type MutationDataTypeUpdateAttributeDateArgs = {
    input: AttributeDateInput
}

export type MutationDataTypeUpdateAttributeLinkArgs = {
    input: AttributeLinkInput
}

export type MutationDataTypeUpdateAttributeNumberArgs = {
    input: AttributeNumberInput
}

export type MutationDataTypeUpdateAttributeSelectionArgs = {
    input: AttributeSelectionInput
}

export type MutationDataTypeUpdateAttributeTextArgs = {
    input: AttributeTextInput
}

export type MutationDeactivatePageArgs = {
    pageID: Scalars['ID']['input']
}

export type MutationDeletePageArgs = {
    pageID: Scalars['ID']['input']
}

export type MutationDuplicatePageArgs = {
    input: DuplicatePageInput
}

export type MutationFolderCreateArgs = {
    input: FolderCreateInput
}

export type MutationFolderDeleteArgs = {
    id: Scalars['ID']['input']
}

export type MutationFolderUpdateArgs = {
    input: FolderUpdateInput
}

export type MutationImportPageArgs = {
    input: ImportDataInput
}

export type MutationMultiEditPagesFromExcelArgs = {
    data: Array<VerifyMultiEditExcelInformationInput>
    websiteID: Scalars['ID']['input']
}

export type MutationPageTreeReorderArgs = {
    input: Array<OrderItemInput>
}

export type MutationPublishPagesArgs = {
    input: Array<PublishItem>
}

export type MutationRealmCreateArgs = {
    input: RealmCreateInput
}

export type MutationRealmUpdateArgs = {
    input: RealmUpdateInput
}

export type MutationSavePageArgs = {
    input: SavePageInput
}

export type MutationUserAssignRealmArgs = {
    realmID: Scalars['String']['input']
    userID: Scalars['String']['input']
}

export type MutationUserCreateArgs = {
    input: UserCreateInput
}

export type MutationUserUnassignRealmArgs = {
    realmID: Scalars['String']['input']
    userID: Scalars['String']['input']
}

export type MutationVerifyMultiEditExcelArgs = {
    elementDefinition: Scalars['String']['input']
    file: Scalars['String']['input']
    websiteID: Scalars['ID']['input']
}

export type MutationVerifyTemplateExcelArgs = {
    file: Scalars['String']['input']
}

export type MutationWebsiteAssignRealmArgs = {
    realmID: Scalars['String']['input']
    websiteID: Scalars['String']['input']
}

export type MutationWebsiteCreateArgs = {
    input: WebsiteCreateInput
}

export type MutationWebsiteSaveArgs = {
    input: WebsiteInput
}

export type OrderItemInput = {
    id: Scalars['ID']['input']
    parent?: InputMaybe<Scalars['ID']['input']>
}

export type Page = {
    __typename?: 'Page'
    createdAt: Scalars['Int']['output']
    currentVersion: PageVersion
    deactivated: Scalars['Boolean']['output']
    deletedAt?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    isTemplate: Scalars['Boolean']['output']
    lastPublished?: Maybe<Scalars['Int']['output']>
    parentPageIdentifier?: Maybe<Scalars['ID']['output']>
    sortIndex: Scalars['Int']['output']
    updatedAt: Scalars['Int']['output']
}

export enum PageRank {
    EIGHT = 'EIGHT',
    FIVE = 'FIVE',
    FOUR = 'FOUR',
    NINE = 'NINE',
    ONE = 'ONE',
    SEVEN = 'SEVEN',
    SIX = 'SIX',
    TEN = 'TEN',
    THREE = 'THREE',
    TWO = 'TWO',
}

export type PageSettingInput = {
    changeFrequency: Frequency
    htmlTitle: Scalars['String']['input']
    includeInSitemapXML: Scalars['Boolean']['input']
    isTemplate: Scalars['Boolean']['input']
    language: Scalars['String']['input']
    linkedLanguagePagesIds?: InputMaybe<Array<Scalars['String']['input']>>
    metaDescription: Scalars['String']['input']
    metaKeywords: Scalars['String']['input']
    pageRank: PageRank
    robotsOption: RobotsOption
    translationID?: InputMaybe<Scalars['String']['input']>
    urlTitle: Scalars['String']['input']
}

export type PageSettings = {
    __typename?: 'PageSettings'
    changeFrequency: Frequency
    htmlTitle: Scalars['String']['output']
    includeInSitemapXML: Scalars['Boolean']['output']
    language: Scalars['String']['output']
    metaDescription: Scalars['String']['output']
    metaKeywords: Scalars['String']['output']
    pageRank: PageRank
    robotsOption: RobotsOption
    translationID?: Maybe<Scalars['String']['output']>
    urlTitle: Scalars['String']['output']
}

export type PageVersion = {
    __typename?: 'PageVersion'
    contents: Scalars['String']['output']
    createdAt: Scalars['Int']['output']
    id: Scalars['ID']['output']
    pageID: Scalars['String']['output']
    pageSettings: PageSettings
    state: State
    updatedAt: Scalars['Int']['output']
    updatedBy: User
}

export type PublishItem = {
    itemID: Scalars['ID']['input']
    type: Scalars['String']['input']
}

export type Query = {
    __typename?: 'Query'
    assets: AssetResponse
    dataItemGet: Array<DataItem>
    dataItemGetHistory: Array<DataItemVersion>
    dataTypeGet: Array<DataType>
    dataTypeGetWithItems: Array<DataTypeWithItems>
    loadBlock: Block
    loadBlockHistory: Array<BlockVersion>
    loadBlocks: Array<Block>
    loadMe: LoggedInUser
    loadPage: Page
    loadPageHistory: Array<PageVersion>
    loadPages: Array<Page>
    loadRealms: Array<Realm>
    loadWebsite: Website
    loadWebsites: Array<Website>
    multiEditDownloadExcel?: Maybe<MultiEditExcelResponse>
}

export type QueryAssetsArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryDataItemGetArgs = {
    dataTypeID: Scalars['ID']['input']
}

export type QueryDataItemGetHistoryArgs = {
    dataItemID: Scalars['ID']['input']
}

export type QueryDataTypeGetArgs = {
    category: DataTypeCategory
    website: Scalars['ID']['input']
}

export type QueryDataTypeGetWithItemsArgs = {
    category: DataTypeCategory
    website: Scalars['ID']['input']
}

export type QueryLoadBlockArgs = {
    blockID: Scalars['ID']['input']
}

export type QueryLoadBlockHistoryArgs = {
    blockID: Scalars['ID']['input']
}

export type QueryLoadBlocksArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryLoadMeArgs = {
    email: Scalars['String']['input']
    firstName: Scalars['String']['input']
    lastName: Scalars['String']['input']
}

export type QueryLoadPageArgs = {
    pageID: Scalars['ID']['input']
}

export type QueryLoadPageHistoryArgs = {
    pageID: Scalars['ID']['input']
}

export type QueryLoadPagesArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryLoadWebsiteArgs = {
    websiteID: Scalars['ID']['input']
}

export type QueryMultiEditDownloadExcelArgs = {
    allowedTypes: MultiEditAllowedTypes
    elementDefinition: Scalars['String']['input']
    pages: Array<Scalars['ID']['input']>
    websiteID: Scalars['ID']['input']
}

export type Realm = {
    __typename?: 'Realm'
    createdAt: Scalars['Int']['output']
    createdBy: User
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    updatedAt?: Maybe<Scalars['Int']['output']>
    updatedBy?: Maybe<User>
    version: Scalars['Int']['output']
}

export type RealmCreateInput = {
    name: Scalars['String']['input']
}

export type RealmUpdateInput = {
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
}

export type Response = {
    __typename?: 'Response'
    statusCode: Scalars['Int']['output']
    success: Scalars['Boolean']['output']
}

export enum RobotsOption {
    BOTH = 'BOTH',
    FOLLOW = 'FOLLOW',
    INDEX = 'INDEX',
    NONE = 'NONE',
}

export type SavePageInput = {
    content?: InputMaybe<Scalars['String']['input']>
    pageID: Scalars['ID']['input']
    pageSettings?: InputMaybe<PageSettingInput>
}

export type SavePageResponse = {
    __typename?: 'SavePageResponse'
    linkedPages: Array<Page>
    page: Page
}

export enum State {
    DELETED = 'DELETED',
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED',
}

export type TemplateExcelLngUrl = {
    language: Scalars['String']['input']
    parentPageID: Scalars['ID']['input']
    urls: Array<TemplateExcelUrl>
}

export type TemplateExcelUrl = {
    alternativeUrl: Scalars['String']['input']
    url: Scalars['String']['input']
}

export type User = {
    __typename?: 'User'
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    realmIDs: Array<Scalars['String']['output']>
}

export type UserCreateInput = {
    defaultWebsiteID: Scalars['String']['input']
    email: Scalars['String']['input']
    firstName: Scalars['String']['input']
    lastName: Scalars['String']['input']
    realmIDs: Array<Scalars['String']['input']>
}

export type VerifyMultiEditExcelInformation = {
    __typename?: 'VerifyMultiEditExcelInformation'
    content: Scalars['String']['output']
    hasChanges: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    metaDescription: Scalars['String']['output']
    metaKeywords: Scalars['String']['output']
    pageTitle: Scalars['String']['output']
    pageUrl: Scalars['String']['output']
}

export type VerifyMultiEditExcelInformationInput = {
    content: Scalars['String']['input']
    hasChanges: Scalars['Boolean']['input']
    id: Scalars['ID']['input']
    metaDescription: Scalars['String']['input']
    metaKeywords: Scalars['String']['input']
    pageTitle: Scalars['String']['input']
    pageUrl: Scalars['String']['input']
}

export type VerifyMultiEditExcelResponse = {
    __typename?: 'VerifyMultiEditExcelResponse'
    information: Array<VerifyMultiEditExcelInformation>
    message: Array<Scalars['String']['output']>
    success: Scalars['Boolean']['output']
}

export type VerifyTemplateExcelInformation = {
    __typename?: 'VerifyTemplateExcelInformation'
    language: Scalars['String']['output']
    urls: Array<Scalars['String']['output']>
}

export type VerifyTemplateExcelResponse = {
    __typename?: 'VerifyTemplateExcelResponse'
    information: Array<VerifyTemplateExcelInformation>
    message: Array<Scalars['String']['output']>
    success: Scalars['Boolean']['output']
    templateName: Scalars['String']['output']
}

export type Website = {
    __typename?: 'Website'
    createdAt: Scalars['Int']['output']
    elementDefinitions: Scalars['String']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    previewURL: Scalars['String']['output']
    productionURL: Scalars['String']['output']
    realmID: Scalars['String']['output']
    updatedAt: Scalars['Int']['output']
    updatedBy: User
}

export type WebsiteCreateInput = {
    elementDefinitions: Scalars['String']['input']
    name: Scalars['String']['input']
    previewURL: Scalars['String']['input']
    productionURL: Scalars['String']['input']
    realmID: Scalars['String']['input']
}

export type WebsiteInput = {
    createdAt: Scalars['Int']['input']
    elementDefinitions: Scalars['String']['input']
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
    previewURL: Scalars['String']['input']
    productionURL: Scalars['String']['input']
    realmID: Scalars['String']['input']
}
