import { DatePicker } from '@mui/x-date-pickers'
import { AttributeDateValue } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeDateValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, onUpdateValue, errors, index, disabled } = props

    const { t } = useTranslation()

    if (attributeValue.__typename !== 'AttributeDateValue') return null
    if (!attribute || attribute.__typename !== 'AttributeDate') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeDateValue>) =>
        onUpdateValue({
            ...attributeValue,
            ...values,
        })

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error = errors.find((error) => error.type === `innerDateEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`)

    return (
        <DatePicker
            label={attribute.common.name}
            value={new Date(value * 1000)}
            onChange={(date) => {
                if (Array.isArray(date))
                    throw Error(t('drawerPanels.dataItemDetailsPanel.attributeDateValueEdit.multipleNotSupported'))
                override({ value: Math.round((date?.getTime() ?? Date.now()) / 1000) })
            }}
            slotProps={{
                textField: {
                    helperText: error?.message ?? '',
                    error: !!error,
                },
            }}
            disabled={disabled}
        />
    )
}
