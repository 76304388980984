import gql from 'graphql-tag'

export const LATEST_BLOCK_VERSION = gql`
    query loadBlock($blockID: ID!) {
        loadBlock(blockID: $blockID) {
            id
            identifier
            createdAt
            currentVersion {
                id
                name
                contents
                state
                updatedBy {
                    id
                    name
                }
                createdAt
                updatedAt
            }
        }
    }
`
