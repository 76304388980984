import gql from 'graphql-tag'

export const LATEST_PAGE_VERSION = gql`
    query loadPage($pageID: ID!) {
        loadPage(pageID: $pageID) {
            id
            createdAt
            updatedAt
            currentVersion {
                id
                createdAt
                updatedAt
                contents
                state
                updatedBy {
                    id
                    name
                }
            }
        }
    }
`
