import gql from 'graphql-tag'
import { fragmentDataItem, fragmentDataItemVersion } from 'graphql/fragments'

export const DATA_ITEM_GET = gql`
    ${fragmentDataItem}
    query dataItemGet($dataTypeID: ID!) {
        dataItemGet(dataTypeID: $dataTypeID) {
            ...fragmentDataItem
        }
    }
`

export const DATA_ITEM_GET_HISTORY = gql`
    ${fragmentDataItemVersion}
    query dataItemGetHistory($dataItemID: ID!) {
        dataItemGetHistory(dataItemID: $dataItemID) {
            ...fragmentDataItemVersion
        }
    }
`
