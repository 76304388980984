import { gql } from '@apollo/client'

export const LOAD_WEBSITE_DATA = gql`
    query loadWebsiteData($website: ID!) {
        loadWebsite(websiteID: $website) {
            id
            name
            elementDefinitions
            previewURL
            realmID
            productionURL
            createdAt
            updatedBy {
                id
                name
            }
            updatedAt
        }
        loadPages(websiteID: $website) {
            id
            parentPageIdentifier
            sortIndex
            deactivated
            isTemplate
            currentVersion {
                id
                pageID
                state
                contents
                pageSettings {
                    htmlTitle
                    urlTitle
                    metaDescription
                    metaKeywords
                    robotsOption
                    includeInSitemapXML
                    pageRank
                    changeFrequency
                    language
                    translationID
                }
                updatedBy {
                    id
                    name
                }
                createdAt
                updatedAt
            }
            createdAt
            updatedAt
            lastPublished
        }
        loadBlocks(websiteID: $website) {
            id
            identifier
            createdAt
            lastPublished
            currentVersion {
                id
                name
                contents
                state
                contents
                updatedBy {
                    id
                    name
                }
                createdAt
                updatedAt
            }
        }
        assets(websiteID: $website) {
            assets {
                id
                name
                sizeBytes
                type
                url
                thumbnailUrl
                tags
                websiteID
                folder
                updatedAt
                updatedBy
                createdAt
                createdBy
            }
            folders {
                id
                websiteID
                name
                tags
                parentFolder
                createdAt
            }
        }
    }
`
