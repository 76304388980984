import gql from 'graphql-tag'

export const DATA_ITEM_CREATE = gql`
    mutation dataItemCreate($dataTypeID: ID!, $input: DataItemInput!) {
        dataItemCreate(dataTypeID: $dataTypeID, input: $input)
    }
`

export const DATA_ITEM_UPDATE = gql`
    mutation dataItemUpdate($input: DataItemInput!) {
        dataItemUpdate(input: $input)
    }
`

export const DATA_ITEM_DELETE = gql`
    mutation dataItemDelete($id: ID!) {
        dataItemDelete(id: $id)
    }
`

export const DATA_ITEM_PUBLISH = gql`
    mutation dataItemPublish($items: [ID!]!) {
        dataItemPublish(items: $items)
    }
`

export const DATA_ITEM_SET_ENABLED = gql`
    mutation dataItemSetEnabled($items: [ID!]!, $enabled: Boolean!) {
        dataItemSetEnabled(items: $items, enabled: $enabled)
    }
`
