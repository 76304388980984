import { Close } from '@mui/icons-material'
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material'
import { AttributeSelectionValue } from 'graphql/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeSelectionValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue, onUpdateValue, errors, index, disabled } = props

    if (attributeValue.__typename !== 'AttributeSelectionValue') return null
    if (!attribute || attribute.__typename !== 'AttributeSelection') return null

    const { value } = attributeValue

    const override = (values: Partial<AttributeSelectionValue>) =>
        onUpdateValue({
            ...attributeValue,
            ...values,
        })

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error = errors.find(
        (error) => error.type === `innerSelectionEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`,
    )

    return (
        <FormControl fullWidth variant={'filled'} disabled={disabled}>
            <InputLabel>{attribute.common.name}</InputLabel>
            <Select
                label={attribute.common.name}
                value={value}
                onChange={(event) => override({ value: event.target.value })}
                error={!!error}
                sx={{ paddingRight: '0px !important' }}
                endAdornment={
                    value !== undefined && value !== '' && value !== null ? (
                        <IconButton
                            size={'small'}
                            sx={{ position: 'absolute', right: 36 }}
                            onClick={() => override({ value: '' })}
                        >
                            <Close />
                        </IconButton>
                    ) : undefined
                }
            >
                {attribute.options.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        <Tooltip title={item.description}>
                            <Typography width={'100%'}>{item.name}</Typography>
                        </Tooltip>
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>{error?.message ?? ''}</FormHelperText>
        </FormControl>
    )
}
