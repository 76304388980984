import { MultiEditDrawerState } from 'drawerPanels/MultiEditPagePanel/MultiEditPagePanel'
import { Action, action, thunk, Thunk } from 'easy-peasy'
import { MULTI_EDIT_PAGES } from 'graphql/mutations/multiEditPagesFromExcel'
import { VERIFY_MULTI_EDIT_EXCEL } from 'graphql/mutations/verifyMultiEditExcel'
import { MULTI_EDIT_DOWNLOAD_EXCEL } from 'graphql/queries/multiEditDownloadExcel'
import {
    MultiEditAllowedTypes,
    Mutation,
    MutationMultiEditPagesFromExcelArgs,
    MutationVerifyMultiEditExcelArgs,
    Query,
    QueryMultiEditDownloadExcelArgs,
    VerifyMultiEditExcelInformation,
} from 'graphql/types'
import store from 'store'
import { fetchData } from 'utils/fetchData'

export type MultiPageEditModel = {
    status: MultiEditDrawerState
    allowedTypes: MultiEditAllowedTypes
    selectedFile: File | undefined
    isLoading: boolean
    messages: string[]
    selectedPages: string[]
    isError: boolean
    excelDownload: string
    verifyMultiEditExcelInformations: VerifyMultiEditExcelInformation[]

    // ------------------------- ACTIONS -------------------------
    setLoading: Action<MultiPageEditModel, boolean>
    setMessage: Action<MultiPageEditModel, { isError: boolean; messages: string[] }>
    setSelectedFile: Action<MultiPageEditModel, File | undefined>
    setStatus: Action<MultiPageEditModel, MultiEditDrawerState>
    resetData: Action<MultiPageEditModel>
    setSelectedPages: Action<MultiPageEditModel, string[]>
    setExcelDownload: Action<MultiPageEditModel, string>
    setAllowedTypes: Action<MultiPageEditModel, MultiEditAllowedTypes>
    setMultiEditExcelInformations: Action<MultiPageEditModel, VerifyMultiEditExcelInformation[]>

    // ------------------------- THUNK -------------------------
    downLoadMultiEditExcel: Thunk<
        MultiPageEditModel,
        { websiteID: string; pages: string[]; allowedTypes: MultiEditAllowedTypes }
    >
    verifyExcel: Thunk<MultiPageEditModel, { websiteID: string; file: string }>
    executeMultiEdit: Thunk<MultiPageEditModel, { websiteID: string; data: VerifyMultiEditExcelInformation[] }>
}

const multiPageEditModel: MultiPageEditModel = {
    allowedTypes: MultiEditAllowedTypes.TEXT,
    status: MultiEditDrawerState.SELECTION,
    selectedFile: undefined,
    isLoading: false,
    messages: [],
    selectedPages: [],
    verifyMultiEditExcelInformations: [],
    isError: false,
    excelDownload: '',

    // Actions
    setMessage: action((state, payload) => {
        state.isError = payload.isError
        state.messages = payload.messages
    }),

    setLoading: action((state, payload) => {
        state.isLoading = payload
    }),

    setSelectedFile: action((state, payload) => {
        state.selectedFile = payload
    }),

    setMultiEditExcelInformations: action((state, payload) => {
        state.verifyMultiEditExcelInformations = payload
    }),

    setAllowedTypes: action((state, payload) => {
        state.allowedTypes = payload
    }),

    resetData: action((state) => {
        state.selectedFile = undefined
        state.status = MultiEditDrawerState.SELECTION
        state.messages = []
        state.selectedPages = []
        state.verifyMultiEditExcelInformations = []
        state.isLoading = false
        state.isError = false
        state.allowedTypes = MultiEditAllowedTypes.TEXT
    }),

    setStatus: action((state, payload) => {
        state.status = payload
    }),

    setSelectedPages: action((state, payload) => {
        state.selectedPages = payload
    }),

    setExcelDownload: action((state, payload) => {
        state.excelDownload = payload
    }),

    // THUNK
    downLoadMultiEditExcel: thunk(async (actions, payload) => {
        actions.setLoading(true)

        const elementDefinitionList = store.getState().model.elementDefinitionList

        //excute api call here
        const response = await fetchData<Query, QueryMultiEditDownloadExcelArgs>(MULTI_EDIT_DOWNLOAD_EXCEL, {
            websiteID: payload.websiteID,
            pages: payload.pages,
            elementDefinition: JSON.stringify(elementDefinitionList),
            allowedTypes: payload.allowedTypes,
        })
        if (response && response.data.multiEditDownloadExcel && response.data.multiEditDownloadExcel.success) {
            actions.setExcelDownload(response.data.multiEditDownloadExcel.excel)
        } else {
            let errorMessages = 'Ein Fehler ist aufgetreten'
            if (response && response.data.multiEditDownloadExcel) {
                errorMessages = response.data.multiEditDownloadExcel.message
            }
            actions.setStatus(MultiEditDrawerState.INFO)
            actions.setMessage({
                isError: true,
                messages: [errorMessages],
            })
        }
        actions.setLoading(false)
    }),

    verifyExcel: thunk(async (actions, payload) => {
        actions.setLoading(true)

        const elementDefinitionList = store.getState().model.elementDefinitionList

        //excute api call here
        const response = await fetchData<Mutation, MutationVerifyMultiEditExcelArgs>(VERIFY_MULTI_EDIT_EXCEL, {
            websiteID: payload.websiteID,
            file: payload.file,
            elementDefinition: JSON.stringify(elementDefinitionList),
        })
        if (response && response.errors) {
            actions.setStatus(MultiEditDrawerState.INFO)
            actions.setMessage({
                isError: true,
                messages: ['An error occurred while verifying the excel file. Try again later.'],
            })
        } else {
            if (response && response.data.verifyMultiEditExcel && response.data.verifyMultiEditExcel.success) {
                actions.setStatus(MultiEditDrawerState.VALIDATION)
                actions.setMultiEditExcelInformations(response.data.verifyMultiEditExcel.information)
            } else {
                let errorMessages: string[] = []
                if (response && response.data.verifyMultiEditExcel) {
                    errorMessages = response.data.verifyMultiEditExcel.message
                }

                actions.setStatus(MultiEditDrawerState.INFO)
                actions.setMessage({
                    isError: true,
                    messages: errorMessages,
                })
            }
        }
        actions.setLoading(false)
    }),

    executeMultiEdit: thunk(async (actions, payload) => {
        actions.setLoading(true)

        //excute api call here
        let isError = false
        const response = await fetchData<Mutation, MutationMultiEditPagesFromExcelArgs>(MULTI_EDIT_PAGES, {
            websiteID: payload.websiteID,
            data: payload.data,
        })
        if (response && response.errors) {
            actions.setStatus(MultiEditDrawerState.INFO)
            actions.setMessage({
                isError: true,
                messages: ['An error occurred while verifying the excel file. Try again later.'],
            })
        } else {
            if (response && response.data.multiEditPagesFromExcel && response.data.multiEditPagesFromExcel.success) {
                for (const page of response.data.multiEditPagesFromExcel.pages) {
                    store.getActions().model.updatePageData({ pageData: page, updateCurrentlySelectedPage: true })
                }
            } else {
                isError = true
            }

            let errorMessage = ''
            if (response && response.data.multiEditPagesFromExcel) {
                errorMessage = response.data.multiEditPagesFromExcel.message
            }

            actions.setStatus(MultiEditDrawerState.INFO)
            actions.setMessage({
                isError,
                messages: [errorMessage],
            })
        }

        actions.setLoading(false)
    }),
}

export default multiPageEditModel
