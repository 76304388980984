import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import React from 'react'
import CustomTreeItem from './CustomTreeItem'

export enum Status {
    PUBLISHED_NO_CHANGES = 'published_no_changes',
    PUBLISHED_WITH_CHANGES = 'published_with_changes',
    PUBLISHED = 'PUBLISHED',
    DEACTIVATED = 'deactivated',
    IS_TEMPLATE = 'is_template',
    NOT_PUBLISHED = 'not_published',
}

export interface PageTreeEntry {
    id: string
    name: string
    status: Status
    subpages: PageTreeEntry[]
}

export interface PageTreeProps {
    pages: PageTreeEntry[]
    expanded: string[]
    onSetExpanded: (ids: string[]) => void
    selectedPage: string | string[]
    onPageSelect: (id: string) => void
    multiSelect?: boolean
}

// render end icon for the tree view
function EndIcon() {
    return <div style={{ width: 24 }} />
}

// recursive render for the tree view
const recursiveRender = (page: PageTreeEntry, multiSelect?: boolean) => {
    return (
        <CustomTreeItem
            key={page.id}
            itemId={page.id}
            label={page.name}
            itemStatus={page.status}
            multiSelect={multiSelect}
        >
            {page.subpages.map((subpage) => recursiveRender(subpage, multiSelect))}
        </CustomTreeItem>
    )
}

const PageTree = (props: PageTreeProps): JSX.Element => {
    const { pages, onPageSelect, selectedPage, expanded, onSetExpanded, multiSelect } = props

    return (
        <SimpleTreeView
            multiSelect={multiSelect ?? false}
            selectedItems={selectedPage}
            expandedItems={expanded}
            expansionTrigger="iconContainer"
            onItemExpansionToggle={(event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
                event.stopPropagation()
                const newExpaned = isSelected ? [...expanded, itemId] : expanded.filter((id) => id !== itemId)
                onSetExpanded(newExpaned)
            }}
            onItemClick={(event: React.SyntheticEvent, nodeId: string) => {
                event.stopPropagation()
                onPageSelect(nodeId)
            }}
            slots={{
                expandIcon: ArrowRightIcon,
                collapseIcon: ArrowDropDownIcon,
                endIcon: EndIcon,
            }}
            sx={{ flexGrow: 1, maxWidth: 400 }}
        >
            {pages.map((page) => recursiveRender(page, multiSelect))}
        </SimpleTreeView>
    )
}

export default PageTree
