import { Action, Thunk, action, thunk } from 'easy-peasy'
import { IMPORT_PAGE } from 'graphql/mutations/importPage'
import { ImportDataInput, ImportType, Mutation, MutationImportPageArgs } from 'graphql/types'
import { fetchData } from 'utils/fetchData'
import store from '../index'

export type ImportModel = {
    importDialogOpen: boolean
    importType: ImportType | ''
    isLoading: boolean
    error: string

    changeDialogState: Action<ImportModel, { openDialog: boolean; type: ImportType | '' }>
    setLoading: Action<ImportModel, boolean>
    setError: Action<ImportModel, string>

    importData: Thunk<ImportModel, ImportDataInput>
}

const importModel: ImportModel = {
    importDialogOpen: false,
    importType: '',
    error: '',
    isLoading: false,

    // Actions
    changeDialogState: action((state, payload) => {
        state.importDialogOpen = payload.openDialog
        state.importType = payload.type
        state.error = ''
    }),

    setLoading: action((state, payload) => {
        state.isLoading = payload
    }),

    setError: action((state, payload) => {
        state.error = payload
    }),

    // Thunksboolean
    importData: thunk(async (actions, payload) => {
        actions.setLoading(true)

        const response = await fetchData<Mutation, MutationImportPageArgs>(IMPORT_PAGE, { input: payload })
        if (response && response.errors) {
            actions.setError('invalid request: ' + JSON.stringify(response))
        } else if (response && !response.data.importPage.success) {
            actions.setError(response.data.importPage.message)
        } else if (response && response.data.importPage.block) {
            store.getActions().model.addBlockToBlockList(response.data.importPage.block)
        } else if (response && response.data.importPage.page) {
            store.getActions().model.addPageToPageList(response.data.importPage.page)
        } else {
            actions.setError("We didn't received any data back. But page or block should be created")
        }

        store.getActions().model.loadAssets(store.getState().model.selectedWebsite?.id ?? '')
        actions.setLoading(false)
    }),
}

export default importModel
