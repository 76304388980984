import gql from 'graphql-tag'

export const BLOCK_CREATE = gql`
    mutation blockCreate($input: BlockCreateInput!) {
        blockCreate(input: $input) {
            id
            createdAt
            lastPublished
            identifier
            currentVersion {
                id
                name
                state
                contents
                updatedBy {
                    id
                    name
                }
                createdAt
                updatedAt
            }
        }
    }
`
