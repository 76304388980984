import { Website } from 'graphql/types'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useStoreState } from 'store/hooks'
import {
    AssetSimplified,
    BlockData,
    Breakpoint,
    PageElement,
    PagePreviewCommandType,
    PagePreviewEvent,
} from 'utils/types'

export type PagePreviewProps = {
    activeBreakpoint: Breakpoint
    pageElements: PageElement[]
    onPreviewPageEvent?: (pe: PagePreviewEvent) => void
    blocks: BlockData[]
    assets: AssetSimplified[]
    disabled: boolean
    editHelpersVisible?: boolean
}

// send a message in our own little format to the parent window
const sendToIframe = (ref: RefObject<HTMLIFrameElement>, messageType: PagePreviewCommandType, payload: any) => {
    if (process.env.NODE_ENV === 'development')
        console.log(`sending message of type ${messageType} to iframe: `, payload)

    const message = JSON.stringify({
        type: messageType,
        payload: payload,
    })
    ref?.current?.contentWindow?.postMessage(message, '*')
}

// handler creation function
const createHandler = (
    selectedWebsite: Website,
    setReady: (ready: boolean) => void,
    onPreviewPageEvent?: (pe: PagePreviewEvent) => void,
) => {
    return (message: MessageEvent<any>) => {
        // filter messages from the iframe URL
        if (!selectedWebsite.previewURL.startsWith(message.origin)) return

        const data = JSON.parse(message.data)

        if (process.env.NODE_ENV === 'development') console.log('received from iframe', data)

        // if the iframe rendering tells us that it is finished rendering, set ready to true so that the proper calls are made
        if (data.type === PagePreviewCommandType.PREVIEW_READY) {
            setReady(true)
        } else if (onPreviewPageEvent) {
            onPreviewPageEvent(data)
        }
    }
}

const PagePreview = (props: PagePreviewProps): JSX.Element => {
    const { onPreviewPageEvent, pageElements, blocks, assets, disabled, activeBreakpoint, editHelpersVisible } = props

    const [ready, setReady] = useState(false)
    const ref = useRef<HTMLIFrameElement>(null)
    const selectedWebsite = useStoreState((state) => state.model.selectedWebsite)
    // this useEffect listens for messages from inside the iframe and passes them to the handler
    useEffect(() => {
        if (selectedWebsite) {
            const handler = createHandler(selectedWebsite, setReady, onPreviewPageEvent)
            window.addEventListener('message', handler)
            return () => window.removeEventListener('message', handler)
        }
    }, [selectedWebsite, onPreviewPageEvent])

    // JSON stringify is rather fast and easy to check for differences between pageElements
    const content = JSON.stringify({ pageElements, assets, blocks })
    useEffect(() => {
        if (ready) {
            sendToIframe(ref, PagePreviewCommandType.UPDATE_CONTENT, {
                pageContent: pageElements,
                blocks: blocks,
                assets: assets,
            })
            sendToIframe(ref, PagePreviewCommandType.REFRESH_ELEMENT_OVERLAYS, {})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- as included in json stringify
    }, [ready, content])

    useEffect(() => {
        if (ready) {
            // the page preview is disabled by enabling the overlay.
            sendToIframe(ref, PagePreviewCommandType.SET_OVERLAY, { enabled: disabled })
        }
    }, [ready, disabled])
    useEffect(() => {
        if (ready) {
            sendToIframe(ref, PagePreviewCommandType.SET_EDIT_HELPERS, { visible: editHelpersVisible })
        }
    }, [ready, editHelpersVisible])

    return (
        <div
            style={{
                height: 'calc(100vh - 72px)',
                width: activeBreakpoint.editorCssWidth,
                overflow: 'hidden',
                transition: 'width 300ms',
            }}
        >
            {selectedWebsite && (
                <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        marginLeft: '50',
                        marginRight: '50',
                        border: '1px solid #888',
                    }}
                    name="pagePreview"
                    src={`${selectedWebsite.previewURL}`}
                    ref={ref}
                ></iframe>
            )}
        </div>
    )
}

export default PagePreview
