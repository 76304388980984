import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getKeycloakInstance } from 'components/AuthWrapper/Auth'
import { ErrorConfig } from 'graphql/types'
import getEnvConfig from './getEnvConfig'

export const useApolloClient = (
    graphQLServerURI: string,
    setError: (error: ErrorConfig) => void,
): ApolloClient<unknown> | undefined => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            console.error(JSON.stringify(graphQLErrors, null, '\t'))
            setError({
                title: 'graphQLErrorTitle',
                content: 'graphQLErrorContent',
                messages: graphQLErrors.map((error) => error.message),
            })
        } else if (networkError) {
            console.error(JSON.stringify(networkError, null, '\t'))
            setError({
                title: 'networkErrorTitle',
                content: 'networkErrorContent',
                messages: [networkError.message],
            })
        }
    })

    const httpLink = new HttpLink({ uri: graphQLServerURI, credentials: 'include' })

    const env = getEnvConfig()
    let newLink: ApolloLink

    if (env.Keycloak.Enabled) {
        const authLink = setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            const instance = getKeycloakInstance()

            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    Authorization: `Bearer ${instance.idToken}`,
                },
            }
        })
        newLink = authLink.concat(httpLink)
    } else {
        const authLink = new ApolloLink((operation, forward) => forward(operation))
        newLink = authLink.concat(httpLink)
    }

    return new ApolloClient({
        cache: new InMemoryCache(),
        link: from([errorLink, newLink]),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                fetchPolicy: 'no-cache',
            },
            mutate: {
                fetchPolicy: 'no-cache',
            },
        },
    })
}
