import { Checkbox, FormControlLabel, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { AutocompleteNestedAsset, AutocompleteNestedPage } from 'components/AutocompleteNested/AutocompleteNested'
import { AttributeLink, AttributeLinkValue } from 'graphql/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkOption, getFirstValidLinkOption, getLinkOptionFromValue, shouldLinkShowOption } from 'utils/dataType/types'
import { AttributeValueEditProps } from './DataItemDetailsPanel'

export const AttributeLinkValueEdit = (props: AttributeValueEditProps) => {
    const { attribute, attributeValue } = props

    if (attributeValue.__typename !== 'AttributeLinkValue') return null
    if (!attribute || attribute.__typename !== 'AttributeLink') return null

    return <Inner {...props} attribute={attribute} attributeValue={attributeValue} />
}

// need to wrap for hook purposes, otherwise its hard to avoid hook order problems
type InnerProps = Omit<AttributeValueEditProps, 'attribute' | 'attributeValue'> & {
    attribute: AttributeLink
    attributeValue: AttributeLinkValue
}
const Inner = (props: InnerProps) => {
    const {
        attribute,
        attributeValue,
        onUpdateValue,
        pages,
        assets,
        errors,
        index,
        disabled,
        selectedVersion,
        showHistory,
    } = props

    const { t } = useTranslation()

    const { value, newWindow } = attributeValue

    const override = (values: Partial<AttributeLinkValue>) =>
        onUpdateValue({
            ...attributeValue,
            ...values,
        })

    const [tab, setTab] = useState<LinkOption>(getFirstValidLinkOption(attribute))
    const [reset, setReset] = useState(true)

    // set initially selected tab if we already have a value
    // also reset if we change selectedVersion or switch history view on/off
    useEffect(() => {
        setReset(true)
    }, [selectedVersion.id, showHistory])
    useEffect(() => {
        if (!reset) return
        if (value) setTab(getLinkOptionFromValue(value))
        else setTab(getFirstValidLinkOption(attribute))
        setReset(false)
    }, [value, attribute, reset])

    // if index is -1, then it is not inside a collection and thus does not have a postfix
    // otherwise the index is appended at the end of the error key
    const error = errors.find((error) => error.type === `innerLinkEmpty${attribute.id}${index >= 0 ? '-' + index : ''}`)

    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={tab}
                exclusive
                size="small"
                fullWidth
                onChange={(_, value: LinkOption) => {
                    // change tab and reset value
                    if (value !== null) {
                        setTab(value)
                        override({ value: '' })
                    }
                }}
                disabled={disabled}
            >
                {Object.keys(LinkOption)
                    .filter((item) => !Number.isNaN(item))
                    .map((item) => (
                        <ToggleButton
                            key={item}
                            value={item}
                            disabled={!shouldLinkShowOption(attribute, item as LinkOption)}
                        >
                            {t(`drawerPanels.dataItemDetailsPanel.attributeLinkValueEdit.${item}`)}
                        </ToggleButton>
                    ))}
            </ToggleButtonGroup>
            {/* TODO: This is throwing uncontrolled/invalid option errors but works fine */}
            {tab === LinkOption.INTERNAL && (
                <AutocompleteNestedPage
                    defaultValue={pages.find((item) => item.id === value)}
                    onChange={(_, value) => override({ value: value?.item.id ?? '' })}
                    inputProps={{ label: attribute.common.name, error: !!error, helperText: error?.message }}
                    disabled={disabled}
                />
            )}
            {tab === LinkOption.EXTERNAL && (
                <TextField
                    label={attribute.common.name}
                    value={value}
                    onChange={(event) => override({ value: event.target.value })}
                    error={!!error}
                    helperText={error?.message ?? ''}
                    disabled={disabled}
                />
            )}
            {tab === LinkOption.FILE && (
                <AutocompleteNestedAsset
                    defaultValue={assets.find((item) => item.id === value)}
                    onChange={(_, value) =>
                        override({ value: value?.item.__typename === 'Asset' ? value?.item?.id : undefined })
                    }
                    inputProps={{ label: attribute.common.name, error: !!error, helperText: error?.message }}
                    disabled={disabled}
                />
            )}
            <FormControlLabel
                control={
                    <Checkbox checked={newWindow} onChange={(event) => override({ newWindow: event.target.checked })} />
                }
                label={t('drawerPanels.dataItemDetailsPanel.attributeLinkValueEdit.newWindow')}
                disabled={disabled}
            />
        </>
    )
}
