import { Autocomplete, Box, Button, Chip, Grid, Stack, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import { filesize } from 'filesize'
import { Asset, AssetType } from 'graphql/types'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface AssetDetailsPanelProps {
    asset: Asset
    tagList: string[]
    onCancel: () => void
    onSubmit: (title: string, tags: string[]) => void
}

interface AssetDetailsPanelInputs {
    Title: string
    Tags: string[]
}

const AssetDetailsPanel = (props: AssetDetailsPanelProps): JSX.Element => {
    const { asset, tagList, onCancel, onSubmit } = props

    const { t } = useTranslation()

    const { handleSubmit, control } = useForm<AssetDetailsPanelInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Title: asset.name,
            Tags: asset.tags ?? [],
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: AssetDetailsPanelInputs) => {
            onSubmit(data.Title, data.Tags)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{asset.name}</Typography>
            </Grid>
            <Grid container spacing={2}>
                {asset.type === AssetType.IMAGE && (
                    <Grid
                        item
                        alignItems={'center'}
                        alignContent={'center'}
                        justifyContent={'center'}
                        style={{ width: '100%', maxHeight: '400px', textAlign: 'center' }}
                        xs={12}
                    >
                        <a href={asset.url} target="_blank" rel="noreferrer">
                            <img src={asset.thumbnailUrl ?? ''} style={{ maxWidth: '100%', maxHeight: '400px' }} />
                        </a>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {/* TODO: move text to translation                     */}
                    <Typography variant="body1"> ID: {asset.id.replace('Asset/', '')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {t('drawerPanels.assetDetailsPanel.size')}: {filesize(asset.sizeBytes)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {t('drawerPanels.assetDetailsPanel.uploaded')}{' '}
                        {format(new Date(asset.createdAt), 'dd.MM.yyyy HH:mm')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {t('drawerPanels.assetDetailsPanel.uploadedBy')} {asset.createdBy}{' '}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="Title"
                        control={control}
                        rules={{
                            required: t('drawerPanels.assetDetailsPanel.nameRequired'),
                            maxLength: { value: 150, message: t('drawerPanels.assetDetailsPanel.nameMaxLength') },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                label={t('drawerPanels.assetDetailsPanel.name')}
                                variant="filled"
                                inputProps={{ maxLength: 150 }}
                                fullWidth={true}
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message ?? ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} mb={3}>
                    <Controller
                        name="Tags"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                multiple
                                fullWidth
                                id="Tags"
                                defaultValue={asset.tags ?? []}
                                options={tagList}
                                freeSolo
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                onChange={(event, value) => {
                                    field.onChange(value)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        label={t('drawerPanels.assetDetailsPanel.tags')}
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOnSave}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}

export default AssetDetailsPanel
