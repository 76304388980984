import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useVersionDaemon } from 'utils/checkVersionDaemon'

export interface BlockSettingsEditPanelProps {
    onCancel: () => void
    onSubmit: (name: string, identifier: string) => void
    label: string
    identifier: string
    saveIsDisabled: boolean
}

interface BlockSettingsEditInputs {
    Label: string
    Identifier: string
}

const BlockSettingsEditPanel = (props: BlockSettingsEditPanelProps): JSX.Element => {
    const { onCancel, onSubmit, label, identifier, saveIsDisabled } = props

    const { t } = useTranslation()

    //initalise deamon to check version
    useVersionDaemon()

    const { handleSubmit, control } = useForm<BlockSettingsEditInputs>({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {
            Label: label,
            Identifier: identifier,
        },
    })

    const handleOnSave = () => {
        handleSubmit((data: BlockSettingsEditInputs) => {
            onSubmit(data.Label, data.Identifier)
        })()
    }

    return (
        <Box display="flex" flexDirection="column" flex={1} p={2}>
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{t('drawerPanels.blockSettingsEditPanel.settings')}</Typography>
            </Grid>
            <Grid container mb={2}>
                <Controller
                    name="Label"
                    control={control}
                    rules={{
                        required: t('drawerPanels.blockSettingsEditPanel.nameRequired'),
                        maxLength: { value: 150, message: t('drawerPanels.blockSettingsEditPanel.nameMaxLength') },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('drawerPanels.blockSettingsEditPanel.name')}
                            variant={'filled'}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>

            <Grid container mb={2}>
                <Controller
                    name="Identifier"
                    control={control}
                    rules={{
                        maxLength: {
                            value: 150,
                            message: t('drawerPanels.blockSettingsEditPanel.identifierMaxLength'),
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <TextField
                            label={t('drawerPanels.blockSettingsEditPanel.identifier')}
                            variant={'filled'}
                            fullWidth={true}
                            {...field}
                            inputProps={{ maxLength: 150 }}
                            helperText={fieldState.error?.message ?? ''}
                        />
                    )}
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button variant="contained" color={'secondary'} onClick={onCancel}>
                    {t('common.cancel')}
                </Button>
                <Button variant="contained" onClick={handleOnSave} disabled={saveIsDisabled}>
                    {t('common.save')}
                </Button>
            </Stack>
        </Box>
    )
}

export default BlockSettingsEditPanel
