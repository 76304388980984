import { Asset, AssetFolder, Page, TemplateExcelUrl } from 'graphql/types'

export const resolvePageURL = (pageID: string, allPages: Page[], omitLastPage?: boolean): string => {
    let rootPath = ''
    const page = allPages.find((p: Page) => p.id === pageID)
    if (page === undefined) return ''
    if (page.parentPageIdentifier && page.parentPageIdentifier.trim().length > 0) {
        rootPath = resolvePageURL(page.parentPageIdentifier, allPages)
    }
    return `${rootPath}/${omitLastPage ? '' : page.currentVersion.pageSettings.urlTitle}`
}

export type FileInfo = {
    url: string
    thumbnailUrl: string
    name: string
    size: number
    type: string
}

export const resolveFileData = (fileID: string, assets: Asset[]): FileInfo | null => {
    for (const asset of assets) {
        if (asset.id === fileID) {
            return {
                url: asset.url ?? '',
                thumbnailUrl: asset.thumbnailUrl ?? '',
                name: asset.name,
                size: asset.sizeBytes,
                type: asset.type,
            }
        }
    }
    return null
}

export const resolveFolderPath = (id: string | undefined, folders: AssetFolder[]): string => {
    const folder = folders.find((item) => item.id === id)
    if (!folder) return '/'
    else return resolveFolderPath(folder.parentFolder ?? undefined, folders) + folder.name + '/'
}

export const verifyUrlIsUnique = (
    originUrl: string,
    urls: TemplateExcelUrl[],
    pageList: Page[],
    pageID: string,
    index: number,
): string => {
    //get all pages whith the same parent
    const pages = pageList.filter((p) => p.parentPageIdentifier === pageID)

    const suffix = index > 0 ? `_${index}` : ''

    //merge url and page url into one array
    const pageUrls = pages.map((p) => p.currentVersion.pageSettings.urlTitle)
    pageUrls.push(...urls.map((u) => (u.alternativeUrl !== '' ? u.alternativeUrl : u.url)))

    //go through all pages and check if the url is unique
    for (const url of pageUrls) {
        if (url === originUrl + suffix) {
            return verifyUrlIsUnique(originUrl, urls, pageList, pageID, index + 1)
        }
    }

    return originUrl + suffix
}
