import { Button, Grid, Typography } from '@mui/material'
import { useStoreState } from 'store/hooks'
import colors from 'utils/colors'
import { resolvePageURL } from 'utils/linkResolver'

export interface MultiPageValidationProps {
    openPageLinkSelector: (templateInfoIndex: number) => void
}

const MultiPageValidation = (props: MultiPageValidationProps): JSX.Element => {
    const { openPageLinkSelector } = props
    const selectedFile = useStoreState((state) => state.multiPageCreateModel.selectedFile)
    const templateName = useStoreState((state) => state.multiPageCreateModel.templateName)
    const verifiesTemplateInfo = useStoreState((state) => state.multiPageCreateModel.verifiesTemplateInfo)
    const pageList = useStoreState((state) => state.model.pageList)

    return (
        <Grid container>
            <Grid item xs={12} mt={2}>
                <Typography variant="h6">Template</Typography>
                <Typography variant="body1">{templateName}</Typography>
                <Typography variant="h6" marginTop={4}>
                    File
                </Typography>
                <Typography variant="body1">{selectedFile?.name}</Typography>
                <Typography variant="h6" marginTop={4}>
                    Status
                </Typography>
                <Typography variant="body1">File ok</Typography>
            </Grid>
            {verifiesTemplateInfo.map((info, index) => {
                let rootURL = ''
                if (info.parentPageID != '') {
                    rootURL = resolvePageURL(info.parentPageID, pageList, false)
                }

                return (
                    <Grid key={index} container item direction={'column'} justifyContent={'space-between'} mb={2}>
                        <Typography variant="h6" marginTop={4}>
                            Language: {info.language}
                        </Typography>
                        <Typography variant="caption">Page: {rootURL}</Typography>
                        <Button
                            variant="contained"
                            color={'secondary'}
                            style={{ marginTop: 16 }}
                            onClick={() => {
                                openPageLinkSelector(index)
                            }}
                        >
                            Select page
                        </Button>
                        <Typography variant="body1" marginTop={4}>
                            Will create pages:
                        </Typography>
                        {info.urls.map((url, index) => (
                            <Typography
                                key={index}
                                variant="caption"
                                color={url.alternativeUrl != '' ? colors.warning : undefined}
                            >
                                {rootURL + '/' + url.url}
                                {url.alternativeUrl != '' ? ' => ' + rootURL + '/' + url.alternativeUrl : ''}
                            </Typography>
                        ))}
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default MultiPageValidation
