import { Add, Close, Edit, List, PlusOne } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Tooltip } from '@mui/material'
import PageTree, { PageTreeEntry } from 'components/PageTree/PageTree'
import { Page } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { getStatus } from 'utils/convertPages'

export interface PageTreePanelProps {
    pages: Page[]
    expanded: string[]
    onSetExpanded: (ids: string[]) => void
    selectedPage: string
    onSelectPage: (id: string) => void
    switchToSortMode?: () => void
    cancel?: () => void
    onPageCreateRoot?: () => void
    onPageCreate?: () => void
    onMultiCreate?: () => void
    onMultiEdit?: () => void
}

const convertPageListToPageTree = (pageList: Page[], currentPage: Page | null): PageTreeEntry[] => {
    const pageTree: PageTreeEntry[] = []

    pageList.forEach((page: Page) => {
        const parentID: string = currentPage ? currentPage.id : ''
        if (page.parentPageIdentifier === parentID) {
            const pageTreeEntry: PageTreeEntry = {
                id: page.id,
                name: page.currentVersion.pageSettings.htmlTitle,
                status: getStatus(page),
                subpages: convertPageListToPageTree(pageList, page),
            }
            pageTree.push(pageTreeEntry)
        }
    })
    return pageTree
}

const PageTreePanel = (props: PageTreePanelProps): JSX.Element => {
    const {
        pages,
        selectedPage,
        expanded,
        onSetExpanded,
        onPageCreate,
        onSelectPage,
        switchToSortMode,
        cancel,
        onMultiCreate,
        onMultiEdit,
    } = props

    const { t } = useTranslation()

    return (
        <Box display="flex" flexDirection="column" flex={1} height="100%">
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {cancel && (
                    <Stack justifyContent={'flex-end'} direction={'row'} style={{ width: '100%' }}>
                        <IconButton aria-label="cancel" onClick={cancel}>
                            <Close />
                        </IconButton>
                    </Stack>
                )}
            </Grid>
            <PageTree
                pages={convertPageListToPageTree(pages, null)}
                onPageSelect={onSelectPage}
                selectedPage={selectedPage}
                expanded={expanded}
                onSetExpanded={onSetExpanded}
            />
            <Box marginTop="auto" display="flex" marginLeft="auto">
                {onPageCreate !== undefined && (
                    <Tooltip title="Create new page">
                        <IconButton onClick={onPageCreate}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                )}
                {onMultiCreate !== undefined && (
                    <Tooltip title="Multi Create new page">
                        <IconButton onClick={onMultiCreate}>
                            <PlusOne />
                        </IconButton>
                    </Tooltip>
                )}
                {onMultiEdit !== undefined && (
                    <Tooltip title="Multi Edit page">
                        <IconButton onClick={onMultiEdit}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                {switchToSortMode !== undefined && (
                    <Tooltip title={t('drawerPanels.pageTreePanel.reorderPages')}>
                        <IconButton onClick={switchToSortMode}>
                            <List fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}

export default PageTreePanel
