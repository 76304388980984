import { Box, Button, Stack } from '@mui/material'
import PageTree from 'components/PageTree/PageTree'
import { Page } from 'graphql/types'
import { useEffect, useState } from 'react'
import { convertPageListToPageTree } from 'utils/convertPages'

export interface MultiSelectPageTreePanelProps {
    pages: Page[]
    selectedPages: string[]
    saveSelectedPages: (ids: string[]) => void
    cancel: () => void
}

const MultiSelectPageTreePanel = (props: MultiSelectPageTreePanelProps): JSX.Element => {
    const { pages, selectedPages, cancel, saveSelectedPages } = props

    const [expanded, setExpanded] = useState<string[]>([])
    const [selected, setSelected] = useState<string[]>([])

    const onHandleOnPageSelect = (id: string) => {
        const newSelected = selected.includes(id)
            ? selected.filter((selectedId) => selectedId !== id)
            : [...selected, id]
        setSelected(newSelected)
    }

    const onHandleSave = () => {
        saveSelectedPages(selected)
    }

    useEffect(() => {
        setSelected(selectedPages)
    }, [selectedPages])

    return (
        <Box display="flex" flexDirection="column" flex={1} height="100%">
            <PageTree
                multiSelect={true}
                pages={convertPageListToPageTree(pages, null)}
                onPageSelect={onHandleOnPageSelect}
                selectedPage={selected}
                expanded={expanded}
                onSetExpanded={setExpanded}
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} padding={3}>
                <Button variant="contained" color={'secondary'} onClick={cancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={onHandleSave}>
                    Select
                </Button>
            </Stack>
        </Box>
    )
}

export default MultiSelectPageTreePanel
