import { Autocomplete, Divider, TextField } from '@mui/material'
import { AttributeCollection, DataType } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { AttributeEditProps } from './AttributeCommonEdit'

export type AttributeCollectionEditProps = AttributeEditProps & { subTypes: DataType[] }

export const AttributeCollectionEdit = (props: AttributeCollectionEditProps) => {
    const { selectedAttribute, setSelectedAttribute, subTypes, errors } = props

    const { t } = useTranslation()

    if (selectedAttribute.__typename !== 'AttributeCollection') return null

    const { dataType } = selectedAttribute

    const override = (values: Partial<AttributeCollection>) =>
        setSelectedAttribute({
            ...selectedAttribute,
            ...values,
        })

    const error = errors.find((error) => error.type === 'innerDataTypeEmpty')

    return (
        <>
            <Divider />
            <Autocomplete
                options={subTypes}
                getOptionLabel={(option) => option.name}
                value={subTypes.find((item) => item.id === dataType)}
                onChange={(_, value) => override({ dataType: value?.id })}
                disabled={selectedAttribute.id !== ''} // only allow editing of subtype on attribute creation, otherwise hell gets loose
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('drawerPanels.attributeDetailsPanel.attributeCollectionEdit.subType')}
                        error={!!error}
                        helperText={error?.message ?? ''}
                    />
                )}
            />
        </>
    )
}
