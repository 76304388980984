import { gql } from '@apollo/client'

export const LOAD_ME = gql`
    query loadMe($email: String!, $firstName: String!, $lastName: String!) {
        loadMe(email: $email, firstName: $firstName, lastName: $lastName) {
            id
            name
            defaultWebsiteID
        }
        loadWebsites {
            id
            name
            realmID
            elementDefinitions
            previewURL
            productionURL
        }
    }
`
